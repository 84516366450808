import styled from 'styled-components';

import { Button, LinkButton } from '../../ui/buttons';

export const CancelButton = styled(LinkButton)`
    display: flex;
    font-weight: bold;
    line-height: 18px;
    height: 22px;
    display: flex;
    align-items: center;
    padding-right: 20px;
    font-style: normal;
`;

export const FormHeader = styled.div`
    color: white;
    font-style: bold;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 64px;
`;

export const SubmitButton = styled(Button).attrs({ type: 'submit' })`
    margin-top: 40px;
`;
