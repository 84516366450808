import React from 'react';
import PropTypes from 'prop-types';

import {
  AccordionContainer,
  ContactsLocationContainer,
} from './styles';

import LocationAccordionItem from './LocationAccordionItem';

function ContactsAccordion({ locations }) {
  return (
    <AccordionContainer>
      {locations.map((l, index) => (
        <ContactsLocationContainer key={index}>
          <LocationAccordionItem opened={l.opened} loc={l} />
        </ContactsLocationContainer>
      ))}
    </AccordionContainer>
  );
}

ContactsAccordion.propTypes = {
  locations: PropTypes.array.isRequired,
};

export default ContactsAccordion;
