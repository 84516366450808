/* eslint-disable */
import isEmail from 'validator/es/lib/isEmail';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import isURL from 'validator/es/lib/isURL';

export const requiredCheckbox = fs => {
  const { value } = fs;
  return {
    ...fs,
    valid: value,
  };
};

const withFuncChain = func => fs => ({ ...fs, valid: !!(func(fs.value || '') || fs.valid) });

const isMobileAllAnyLocale = value => isMobilePhone(value, false, { strictMode: false });

export const email = withFuncChain(isEmail);
export const mobilePhone = withFuncChain(isMobileAllAnyLocale);
export const url = withFuncChain(isURL);
