import React from 'react';
import Intro from '../../../../components/Intro';
import { useModalState } from '../../../../hooks';

import { IntroSectionHeader } from '../../../../components/Intro/styles';
import { Title, ToggleButton, IntroWrapper } from './styles';

import strings from './strings';
import useWindowSize from '../../../../hooks/useWindowSize';

const header = <IntroSectionHeader>{strings.header}</IntroSectionHeader>;

function TitleModal() {
  const [windowWidth] = useWindowSize();
  const isMobile = windowWidth <= 600;
  const expanded = useModalState(!isMobile);

  return (
    <Title>{strings.title}<br /><br />
      {expanded.opened && strings.description}
      {!isMobile && strings.description}
      {isMobile && <ToggleButton onClick={expanded.toggle}>{expanded.opened ? 'Show Less' : 'Show More'}</ToggleButton>}
    </Title>
  );
}

function IntroSection() {
  const title = <TitleModal />;

  return <Intro
    WrapperComponent={IntroWrapper}
    {...{ header, title }}
  />;
}

export default IntroSection;
