/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import isEmail from 'validator/es/lib/isEmail';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import isURL from 'validator/es/lib/isURL';

import { contacts } from '../../data';
import { sendMail } from '../../services/mailService';

import {
  ContactsSectionStyled, ContactsSectionInner,
  ContactsSectionHeader, IframeTabs,
  IframeTabsTitle, IframeTabsInner,
  IframeTabsWrapIframe, IframeTabsHeadersItem,
  IframeTabsWrapHeaders, IframeTabsHeaders,
  IframeTabsBody, IframeTabsBodyItem,
  IframeTabsBodyItemCity, IframeTabsBodyItemAddress1,
  IframeTabsBodyItemAddress2, IframeTabsBodyItemPhone,
  IframeTabsFrom, IframeTabsFromField, MapMarker,
  MapContainer,
} from './styles';
import { Button } from '../../ui';
import mapStyles from './mapStyles.json';
import useWindowSize from '../../hooks/useWindowSize';
import FooterMobile from './Mobile/FooterMobile';

const getMapOptions = maps => ({
  disableDefaultUI: true,
  mapTypeControl: false,
  streetViewControl: false,
  styles: mapStyles,
  gestureHandling: 'cooperative',
});

const FooterComponent = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabCity, setActiveTabCity] = useState(0);
  const [contactInfo, setContactInfo] = useState('');
  const [windowWidth] = useWindowSize();

  const markerLat = contacts[activeTab].cities[activeTabCity].lat;
  const markerLng = contacts[activeTab].cities[activeTabCity].lng;

  const changeTab = index => () => {
    setActiveTab(index);
    setActiveTabCity(0);
  };

  const handleSetContactInfo = event => {
    setContactInfo(event.target.value);
  };

  const handleSendMail = async () => {
    if (isEmail(contactInfo) || isMobilePhone(contactInfo) || isURL(contactInfo)) {
      await sendMail({
        subject: 'Contact Me',
        text: contactInfo,
      });
    }
  };

  const tabHeaders = contacts.map(
    (item, key) => (
      <IframeTabsHeadersItem
        onClick={changeTab(key)}
        key={key}
        active={key === activeTab}
      >
        {item.country}
      </IframeTabsHeadersItem>
    ),
  );

  const tabBody = (() => {
    const item = contacts[activeTab];
    return (
      item.cities.map(
        (city, key) => (
          <IframeTabsBodyItem key={key} onMouseEnter={() => setActiveTabCity(key)}>
            <IframeTabsBodyItemCity
              // @TODO: Remove arrow function
              // onClick={() => setActiveTabCity(key)}
              active={key === activeTabCity}
            >
              {item.country} - {city.city}
            </IframeTabsBodyItemCity>

            <IframeTabsBodyItemAddress1>
              {city.address1}
            </IframeTabsBodyItemAddress1>
            <IframeTabsBodyItemAddress2>
              {city.address2}
            </IframeTabsBodyItemAddress2>
            <IframeTabsBodyItemPhone>
              {city.phone}
            </IframeTabsBodyItemPhone>
          </IframeTabsBodyItem>
        ),
      )
    );
  })();

  if (windowWidth <= 600) {
    return <FooterMobile contacts={contacts} />;
  }

  return (
    <ContactsSectionStyled>
      <MapContainer>
        <ContactsSectionInner>
          <ContactsSectionHeader>
            Contacts
          </ContactsSectionHeader>
          <IframeTabs>
            <IframeTabsInner>
              <IframeTabsWrapHeaders>
                <IframeTabsTitle>
                  Office on the map:
                </IframeTabsTitle>
                <IframeTabsHeaders>
                  {tabHeaders}
                </IframeTabsHeaders>
              </IframeTabsWrapHeaders>
              <IframeTabsBody>
                {tabBody}
              </IframeTabsBody>
            </IframeTabsInner>
            <IframeTabsFrom>
              <IframeTabsFromField
                type="text"
                placeholder="Enter your email, phone number, or connect via social media. Get in touch."
                onChange={handleSetContactInfo}
              />
              <Button type="light" onClick={handleSendMail}>
                Write to me
              </Button>
            </IframeTabsFrom>
            <IframeTabsWrapIframe>
              <GoogleMapReact
                bootstrapURLKeys={{ language: 'en', region: 'US', key: 'AIzaSyAQ5Vd05deNNUVhhtjJ37ahxu75m_aZrDc' }}
                center={{
                  lat: markerLat,
                  lng: markerLng - 0.008,
                }}
                defaultZoom={16}
                shouldUnregisterMapOnUnmount
                options={getMapOptions}
              >
                <MapMarker
                  lat={markerLat}
                  lng={markerLng}
                />
              </GoogleMapReact>

            </IframeTabsWrapIframe>
          </IframeTabs>
        </ContactsSectionInner>
      </MapContainer>
    </ContactsSectionStyled>
  );
};

function areEqual() {
  return true;
}

export const Footer = React.memo(FooterComponent, areEqual);
