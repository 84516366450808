import styled from 'styled-components';

export const ContainerStyled = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        max-width: 1200px;
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        max-width: 704px;
    };
`;
