/* eslint-disable */
import React from 'react';

import { FullPage } from '../../ui';
import { IntroSection } from './components';

import { Footer } from '../../components/Footer';

function PrivacyPage() {
  return (
    <FullPage scrollable>
        <IntroSection />
        <Footer />
    </FullPage>
  );
}

export default PrivacyPage;
