import * as StateExtractors from '../../Forms/state-extractors';

import * as Validators from '../../Forms/validators';

export default [
  {
    id: 'contact',
    type: 'textarea',
    placeholder: 'Enter your email, phone number, or connect via social media. Get in touch.',
    getState: StateExtractors.htmlInputElement,
    required: true,
    middleware: [
      Validators.email,
      Validators.mobilePhone,
      Validators.url,
    ],
  },
];
