import React from 'react';
import Intro from '../../../../components/Intro';

import { IntroSectionHeader } from '../../../../components/Intro/styles';
import { Title, IntroWrapper } from './styles';

import strings from './strings';

const header = <IntroSectionHeader>{strings.header}</IntroSectionHeader>;


function TitleModal() {
  return (
    <Title>{strings.title}
    </Title>
  );
}

function OutsourceWithUsSection() {
  const title = <TitleModal />;

  return <Intro
    WrapperComponent={IntroWrapper}
    {...{ header, title }}
  />;
}

export default OutsourceWithUsSection;
