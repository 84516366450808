import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import { useModalState } from '../../../hooks';
import {
  Arrow,
  LocationHeader,
  CitiesContainer,
  CityContainer,
  CityHeader,
  CityAddress,
  MapContainer,
  MapMarker,
  MapBackground,
  MarkerAbsoluteCenter,
} from './styles';
import mapStyles from '../mapStyles.json';

import arrowUp from '../../../assets/img/arrow_up.svg';
import arrowDown from '../../../assets/img/arrow_down.svg';

const getMapOptions = maps => ({
  disableDefaultUI: true,
  mapTypeControl: false,
  streetViewControl: false,
  styles: mapStyles,
  gestureHandling: 'cooperative',
});

function LocationAccordionItem({ loc, opened }) {
  const accordion = useModalState(opened);
  return (
    <CitiesContainer>
      <LocationHeader onClick={accordion.toggle}>
        <span>{loc.country}</span>
        <Arrow bg={accordion.opened ? arrowUp : arrowDown} />
      </LocationHeader>
      {
        accordion.opened && loc.cities.map((city, index) => (
          <CityContainer key={index}>
            <CityHeader>
              {city.city}
            </CityHeader>
            <CityAddress>
              {city.address1}
            </CityAddress>
            <CityAddress>
              {city.address2}
            </CityAddress>
            <CityAddress>
              {city.phone}
            </CityAddress>
            <MapContainer>
              <a href={`https://www.google.com/maps/search/?api=1&query=${city.lat},${city.lng}`} target="_blank" rel="noopener noreferrer">
                <MapBackground />
                <MarkerAbsoluteCenter>
                  <MapMarker />
                </MarkerAbsoluteCenter>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyAQ5Vd05deNNUVhhtjJ37ahxu75m_aZrDc' }}
                  defaultZoom={10}
                  center={{
                    lat: city.lat,
                    lng: city.lng,
                  }}
                  options={getMapOptions}
                />
              </a>
            </MapContainer>
          </CityContainer>
        ))
      }
    </CitiesContainer>
  );
}

LocationAccordionItem.propTypes = {
  loc: PropTypes.object.isRequired,
  opened: PropTypes.bool,
};

export default LocationAccordionItem;
