import styled from 'styled-components';

import { theme } from '../../../constants';

const { cadetBlue, fountainBlue } = theme.colors;

export const TextArea = styled.textarea`
    width: 100%;
    height: 50px;
    position: relative;
    padding-bottom: 16px;
    background: none;
    border: none;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    border-bottom: 2px solid ${cadetBlue}; 
    &::placeholder {
        color: ${cadetBlue};
    };
    &:focus {
        outline: none;
        border-bottom-color: ${fountainBlue}; 
    };

    &:not([value=""]) ~ label,
    &:focus ~ label {
        top: -14px;
        font-size: 12px;
        font-style: regular;
        line-height: 13px;
        color: ${cadetBlue};
    }
`;

export const TextAreaMobile = styled.textarea`
    width: 100%;
    height: 60vh;
    background-color: #E28D93;
    border-radius: 3px;
    border: none;

    color: white;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    padding: 16px 0 0 14px;

    &::placeholder {
        color: white;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 180%;
    };

    &:focus {
        outline: none;
    };
`;
