import { useState } from 'react';

export default function useModalState(initialOpened) {
  const [opened, setOpened] = useState(initialOpened);

  return {
    opened,
    open() {
      setOpened(true);
    },
    close() {
      setOpened(false);
    },
    toggle() {
      setOpened(o => !o);
    },
  };
}
