import styled from 'styled-components';
import { LinkButton } from '../../ui';

export const HeaderStyled = styled.header`
    width: 100%;
    padding: 50px 24px;
    background-color: transparent;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        background-color: #000;
        padding: 24px 0;
    };
`;

export const HeaderInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        padding: 0 10px;
    };
`;
export const HeaderNavSubNavItemLink = styled.a`
    text-decoration: none;
    display: block;
    padding: 0 10px;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.poppins};
    font-weight: 600;
    font-size: 14px;
    line-height: 240%;
    cursor: pointer;
    transition: all .4s;
    &:hover{
        color: ${props => props.theme.colors.fountainBlue};
        background-color: ${props => props.theme.colors.charade};
    }
`;
export const HeaderNavSubNavItem = styled.li`

`;
export const HeaderNavSubNav = styled.ul`
    margin: 0;
    padding: 20px 0px;
    list-style: none;
    border-radius: 3px;
    background-color: ${props => props.theme.colors.shark};
`;
export const HeaderNavItemLink = styled.a`
    text-decoration: none;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.poppins};
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
    transition: all .4s;
    &::after{
        text-decoration: none;
        content: '';
        display: ${props => (props.dropdown ? 'inline-block' : 'none')};
        width: 0px;
        height: 0px;
        margin-bottom: 1px;
        margin-left: 8px;
        border-color: #fff;
        border-style : solid;
        border-width: 0 2px 2px 0;
        padding: 3px;
        transform: rotate(45deg);
        border-radius: 2px;
        transition: border-color .4s;
    }
`;

export const HeaderNavItem = styled.div`
    position: relative;
    line-height: 200%;
    &:hover{
        ${HeaderNavItemLink}{
            color: ${props => props.theme.colors.fountainBlue};
            &::after{
                border-color: ${props => props.theme.colors.fountainBlue};
                transform: rotate(-135deg);
                margin-bottom: -3px;
            }
        };
        ${HeaderNavSubNav}{
            display: block;
        };
    };
    ${HeaderNavSubNav}{
        display: none;
        min-width: 140px;
        position: absolute;
        right: 0;
        top: 100%;
    };
`;
export const HeaderNav = styled.nav`
    display: flex;
    ${HeaderNavItem}{
        margin-right: 55px;
        &:last-child{
            margin-right: 0;
        }
    }
`;
export const HeaderNavSocialWrap = styled.div`
    display: flex;
    align-items: center;
    ${HeaderNav}{
        margin-right: 55px;
    }
`;

export const HeaderSocialNetworksItem = styled.a`
    display: flex;
    svg{
        width: 1em;
        height: 1em;
        font-size: 24px;
        cursor: pointer;
        *{
            transition: all .4s;
        }
        &:hover{
            *{
                fill: ${props => props.theme.colors.fountainBlue};
            }
        }
    }
`;

export const HeaderSocialNetworks = styled.div`
    display: flex;
    ${HeaderSocialNetworksItem}{
        margin-right: 15px;
        &:last-child{
            margin-right: 0;
        }
    }
`;

export const Menu = styled.div`
    position: absolute;
    top: 0px;
    padding: 30px 32px;
    width: 100%;
    height: 100%;
    height: 100%;
    left: -300px;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 0.3s;
`;

export const Item = styled.a`

`;

export const ItemGroupContainer = styled.div`
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 240%;
    /* or 43px */

    display: flex;
    flex-direction: column;
    margin-top: 16px;
    color: #ABB2BF;
`;

export const ItemGroupTitle = styled.a`
    display: block;
    margin-top: 24px;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
`;

export const CancelButton = styled(LinkButton)`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
    cursor: pointer;
    user-select: none;
`;
