import styled, { keyframes } from 'styled-components';
import { DetailSlider } from '../../../../components';
import cursorExpand from '../../../../assets/img/cursor_expand.svg';
import cursorNextSlide from '../../../../assets/img/cursor_next.svg';

const blink = keyframes`
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
`;

export const HorizontalListCardText = styled.p`
    max-width: 310px;
    color: #fff;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        max-width: 604px;
        margin: 0;
    };
`;

export const PreloadPrintCursorCustom = styled.div`
    display: inline-block;
    width: 19px;
    height: 40px;
    margin-left: 10px;
    background-color: ${props => props.theme.colors.fountainBlue || '#fff'};
    animation: ${blink} 1s step-end infinite;
`;
export const HorizontalListCardWrapText = styled.div`
    padding: 40px;
    background-color: #000;
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        padding: 24px 40px;
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding: 0;
        background: transparent; 
    };
`;
export const HorizontalListCardHeader = styled.h4`
    max-width: 260px;
    margin: 0;  
    color: ${props => props.color || '#fff'};
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        max-width: 100%;
    };
`;
export const HorizontalListCardWrapImg = styled.div`
    display: flex;
    align-items: flex-end;
    background-color: black;
    padding: 24px 40px;
    ${props => (props.expanded ? 'padding-left: 0px;' : '')}
    background: url(${props => (props.bg)});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding: 0;
        background: transparent;
    }
`;
export const HorizontalListCard = styled.div`
    pointer-events: all;
    cursor: url(${props => (props.expanded ? cursorNextSlide : cursorExpand)}), auto;
    &:hover {
        background-color: black;
    }
    flex: 1;
    ${props => (props.expanded ? `
        width: 100%;
        height: 100%;
        z-index: 20;
        position: absolute;
        top: 0px;
        left: 0px;
        transition: height 2ms, width: 2ms ease-in-out;
        &:hover {
            transform: scale(1);
        }
    ` : '')}
    ${HorizontalListCardWrapImg}{
        height: ${props => (props.expanded ? '100%' : '70%')};
    }
    ${HorizontalListCardWrapText}{
        height: 30%;
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 22px 32px;
        background: url(${props => (props.bg)});
        background-position: right 30%;
        background-size: cover;
        background-repeat: no-repeat;
        ${HorizontalListCardWrapImg}{
            height: auto;
            margin-bottom: 25px;
        };
        ${HorizontalListCardWrapText}{
            height: auto;
        };
    };
`;
export const HorizontalList = styled.div`
    background-color: black;
    display: flex;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        flex-direction: column;
    }
`;

export const PreloadPrintText = styled.div`
    padding: 0px 8px;
    color: ${props => props.theme.colors.fountainBlue || '#fff'};
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2; 
    -webkit-transition: opacity 200ms ease-in-out;
    -moz-transition: opacity 200ms ease-in-out;
    -ms-transition: opacity 200ms ease-in-out;
    -o-transition: opacity 200ms ease-in-out;
    transition: opacity 200ms ease-in-out;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        text-align: center;
    };
    display: flex;
    align-items: center;
`;

export const PreloadPrintCursor = styled.div`
    display: inline-block;
    width: 19px;
    height: 40px;
    margin-left: 10px;
    vertical-align: bottom;
    background-color: ${props => props.theme.colors.fountainBlue || '#fff'};
`;

export const PreloadPrint = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #000;
`;

export const ExpertiseSectionStyled = styled.div`
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    ${HorizontalList}{
        height: 100%;
    }
`;

export const AbsoluteSectionStyled = styled.div`
    height: 100vh;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    ${PreloadPrint}{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    ${DetailSlider}{
        height: 100%;
    }
`;

export const PreloadSectionStyled = styled(AbsoluteSectionStyled)`
    z-index: 3;
`;

export const SliderSectionStyled = styled(AbsoluteSectionStyled)`
    z-index: 0;
`;

export const MainContainer = styled.section`
    position: relative;
    overflow-y: ${props => (props.scrollable ? 'scroll' : 'hidden')};
    overflow-x: hidden;
    height: 100vh;
`;
