import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { DetailSlider, DetailSliderItem } from '../../../../components';

import {
  HorizontalListCard,
  HorizontalListCardWrapImg,
  HorizontalListCardHeader,
  HorizontalListCardWrapText,
  HorizontalListCardText,
  SliderSectionStyled,
} from './styles';

const sliderConfig = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
};

function ExpertiseListItem({ expertiseSlider, header, headerColor, img, text, startWith }) {
  const [expanded, setExpanded] = useState(false);
  const slider = useRef(null);

  function toggleExpanded() {
    setExpanded(e => !e);
  }

  function slideForward() {
    slider.current.slickNext();
  }

  function rearange(array, index) {
    return [...array.slice(index, array.length), ...array.slice(0, index)];
  }

  const sliderItems = rearange(expertiseSlider, startWith).map((item, index) => (
    <DetailSliderItem
      key={index}
      data={item}
      onBack={toggleExpanded}
      onNextSlide={slideForward}
    />
  ));

  function renderSlider() {
    return (
      <SliderSectionStyled>
        <DetailSlider ref={slider} {...sliderConfig}>
          {sliderItems}
        </DetailSlider>
      </SliderSectionStyled>
    );
  }

  return (
    <HorizontalListCard bg={img} expanded={expanded} onClick={expanded ? slideForward : toggleExpanded}>
      <HorizontalListCardWrapImg expanded={expanded} bg={img}>
        <HorizontalListCardHeader color={headerColor}>
          {expanded ? renderSlider() : header}
        </HorizontalListCardHeader>
      </HorizontalListCardWrapImg>
      {!expanded && (
        <HorizontalListCardWrapText>
          <HorizontalListCardText>
            {text}
          </HorizontalListCardText>
        </HorizontalListCardWrapText>
      )}
    </HorizontalListCard>
  );
}

ExpertiseListItem.propTypes = {
  header: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  expertiseSlider: PropTypes.array.isRequired,
  startWith: PropTypes.number.isRequired,
};

export default ExpertiseListItem;
