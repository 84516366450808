import styled from 'styled-components';

export const Header = styled.div`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    padding-bottom: 12px;

    color: #E5C07B;
`;

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 180%;
    padding-bottom: 32px;

    color: #FFFFFF;
`;

export const ExpertiseScreen = styled.section`
    height: 100%;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 80px 10px 60px 10px;
    background: url(${props => props.bg}) center center no-repeat;
    background-size: auto 100%;
    background-position: top;
`;

export const ExpertiseSectionContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
