import img1 from '../assets/img/horizontal_list_1.jpg';
import img2 from '../assets/img/horizontal_list_2.jpg';
import img3 from '../assets/img/horizontal_list_3.jpg';
import { theme } from '../constants';

export default [
  {
    img: img1,
    header: 'Enterprise Software',
    headerColor: theme.colors.harvestGold,
    title: 'Scale is no issue. We provide custom applications and development tools for organizations of any size to solve your business’s unique needs.',
    text: 'Team Harbour has the means to help you evolve with tech conversant in the industry’s best standard practices. Boost sales, expand your client base, and increase customer satisfaction with our streamlined custom systems.',
  },
  {
    img: img2,
    header: 'Web Applications',
    headerColor: theme.colors.coral,
    title: 'We value efficiency, control, and usability in all of our industry focused web application products.',
    text: 'Team Harbour’s seasoned professionals are ready for any enterprise project scope with full spectrum development services, microservices architectures, big data processing, legacy software upgrades, integrations, and much more.',
  },
  {
    img: img3,
    header: 'Mobile Applications',
    headerColor: theme.colors.olivine,
    title: 'Team Harbour delivers peerless mobile experiences for both consumer-facing and corporate environments.',
    text: 'We will build, test, and release highly functional and user oriented mobile apps compatible on any device and operating system. Extend your reach and rest assured that each step of the project is thoroughly executed, all powered by state-of-the-art back-end systems.',
  },
];
