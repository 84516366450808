import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  CardsContainer,
  Title,
} from './styles';

function findMultiplier(length, num) {
  let mlt = num;
  for (let i = mlt; i > 0; i--) {
    if (length / mlt % 1 === 0) {
      mlt = length / mlt;
    }
  }
  return mlt;
}

function CardsRow({ title, cards, renderCard, containerStyle, indent, rows }) {
  const Rows = [];
  const multiplier = findMultiplier(cards.length, rows);
  for (let i = 0; i < rows + 1; i += multiplier) {
    Rows.push((
      <CardsContainer style={containerStyle}>
        {
          cards.slice(i, i + multiplier).map(renderCard)
        }
      </CardsContainer>
    ));
  }

  return (
    <Container indent={indent}>
      <Title>{title}</Title>
      {Rows}
    </Container>
  );
}

CardsRow.propTypes = {
  renderCard: PropTypes.func.isRequired,
  cards: PropTypes.array.isRequired,
  containerStyle: PropTypes.object,
  title: PropTypes.string,
  indent: PropTypes.bool,
  rows: PropTypes.number,
};

CardsRow.defaultProps = {
  rows: 1,
};

export default CardsRow;
