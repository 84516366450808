import styled from 'styled-components';
import {
  SectionTitle,
  SectionStyled,
  SectionHeader,
} from '../styles';

import { CardsContainer } from '../CardsRow/styles';

export const Container = styled(SectionStyled)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 40px;
      height: auto;
    }
`;

export const TechnologiesHeader = styled(SectionHeader)`
  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    font-size: 24px;
    line-height: 30px
  }
`;

export const TechnologiesTitle = styled(SectionTitle)`
  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    font-size: 18px;
    line-height: 180%;
  }
`;

export const WebRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 54px;
  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const MobileRow = styled.div`
  margin-top: 74px;
  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  ${CardsContainer} {
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
      justify-content: flex-start !important;
    }
  }
`;

export const MultipleCardsWrapper = styled.div`
  color: white;
  font-family: ${props => props.theme.fonts.sourceCodePro};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const FancyBorder = styled.div`
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
  height: 8px;
  width: 100%;
  margin-bottom: 20px;
  opacity: .5;
  margin-top: 15px;
`;

export const MultipleCardsRow = styled.div`
  display: flex;
  @media (max-width: ${p => p.theme.breakpoints.laptop}) {
    width: 100%;
    justify-content: space-around;
  }
`;
