import styled from 'styled-components';
import { FullPageSection } from '../../../ui';

export const SectionHeader = styled.h1`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    color: #fff;
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
`;

export const SectionTitle = styled.h3`
    font-family: ${props => props.theme.fonts.poppins};
    color: #fff;
    font-size: 18px;
    line-height: 1.8;
    font-weight: normal;
`;

export const SectionStyled = styled(FullPageSection)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 170px 90px 100px 76px;
    background-color: #000;
    ${SectionHeader}{
        max-width: 948px;
        margin-top: 5px;
        margin-bottom: 12px;
    }
    ${SectionTitle}{
        max-width: 796px;
        margin-top: 52px;
        margin-bottom: 5px;
    }
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        padding-bottom: 90px;
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding-bottom: 60px;
    };
`;
