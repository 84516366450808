import styled from 'styled-components';
import { InputField } from '../InputField';

import { FormFieldTypes } from '../../../constants';
import { Button } from '../../../ui/buttons';

export const FormContainer = styled.div`
    width: 100%;
    padding-bottom: 70px;
    max-width: 692px;
    margin: 0 auto;
    @media (max-width: 700px) {
        max-width: 90%;
    };
`;

export const FormFieldContainer = styled(InputField)`
    margin-bottom: ${({ type }) => (type === FormFieldTypes.TEXT || type === FormFieldTypes.EMAIL || type === FormFieldTypes.FILE ? '56px' : '16px')};
    ${({ type }) => (type === 'textarea' ? `
        margin: 32px 0 24px 0;
        height: 30vh;
    ` : '')}
    ${props => (props.type === FormFieldTypes.CHECKBOX ? `
        height: auto;
    ` : '')}
`;

export const SubmitButton = styled(Button).attrs({ type: 'submit' })`
    margin-top: 40px;
`;

export const FormStyled = styled.form`
    padding-bottom: 70px;
`;
