/* eslint-disable max-len */
import React from 'react';

import Accordion from '../Accordion';
import {
  Title,
  SectionContainer,
  HeaderContainer,
  BackgroundText,
  AccordionContainer,
  Header,
} from './styles';

import teamsData from './teamsData.json';

function AccordionSection() {
  return (
    <SectionContainer>
      <HeaderContainer>
        <Header>
          How We Work
          <BackgroundText>
            Everything is very simple
          </BackgroundText>
        </Header>
        <Title>
          Team Harbour will select, manage, and maintain a core team of IT professionals for your business. We ensure a productive environment and provide a flexible, convenient model of co-operation. Our experienced project managers will work in accordance with your preferred project management methodology, whether it’s advanced Agile/SCRUM or traditional Waterfall.
          <br /><br /><br />
          Let us know your business requirements and we will draw mutual terms on cooperation format, team structure, and managerial control.
          <br /><br />
          You are in good hands!
        </Title>
      </HeaderContainer>
      <AccordionContainer>
        <Header>
          Here is an overview of our areas of expertise:
        </Header>
        <Accordion
          items={teamsData}
        />
      </AccordionContainer>
    </SectionContainer>
  );
}

export default AccordionSection;
