import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Portal from '../../utils/Portal';

import { ModalWrapper, ModalScrollContainer } from './styles';

function Modal({ children, wrapperProps, scrollContainerRef }) {
  useEffect(() => {
    document.getElementById('root').style.display = 'none';
    return () => {
      document.getElementById('root').style.display = 'block';
    };
  }, []);

  return (
    <Portal>
      <ModalWrapper {...wrapperProps}>
        <ModalScrollContainer ref={scrollContainerRef}>
          {children}
        </ModalScrollContainer>
      </ModalWrapper>
    </Portal>
  );
}

Modal.propTypes = {
  children: PropTypes.func.isRequired,
  wrapperProps: PropTypes.object,
  scrollContainerRef: PropTypes.string,
};

export { Modal };
