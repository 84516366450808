import React from 'react';
import PropTypes from 'prop-types';

import {
  ChecklistItemStyled,
  CheckImage,
} from './styled';

import checklistDone from '../../../../../../assets/img/checklist_done.svg';

function ChecklistItem({ children }) {
  return (
    <ChecklistItemStyled>
      <CheckImage
        src={checklistDone}
        alt="checklist_done"
      />
      {children}
    </ChecklistItemStyled>
  );
}

ChecklistItem.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ChecklistItem;
