import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '..';
import {
  Form,
  CancelButton,
  FormHeader,
  SubmitButton,
} from '../Forms';

import {
  MessageContainer,
  HighlightText,
  MessageText,
  MessageActionButton,
} from '../Forms/FormMessages';

import formFields from './formFields';
import { sendMail } from '../../services/mailService';
import { fieldsStateToString, parseAttachment } from '../../utils/formFields';

function LeaveCVModal({ onClose }) {
  const [result, setResult] = useState(null);

  function resetResult() {
    setResult(null);
  }

  async function handleSubmit(fieldsState) {
    const attachment = await parseAttachment(fieldsState);

    const sent = await sendMail({
      subject: 'Leave CV',
      text: fieldsStateToString(fieldsState),
      attachment,
    });

    setResult({ error: !sent, success: sent });
  }

  function renderHeader() {
    return (
      <>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <FormHeader>
          We always accept CVs and portfolios even if there are no positions immediately available.
        </FormHeader>
      </>
    );
  }

  function renderFooter() {
    return (<SubmitButton type="submit">Get In Touch</SubmitButton>);
  }

  function renderForm() {
    return (
      <Form
        fields={formFields}
        onSubmit={handleSubmit}
        renderHeader={renderHeader}
        renderFooter={renderFooter}
      />
    );
  }

  function renderSuccess() {
    return (
      <MessageContainer>
        <MessageText>
        Your CV have been sent<br />
          <HighlightText color="olivine">successfully</HighlightText>
        </MessageText>
        <MessageActionButton onClick={onClose}>Ok</MessageActionButton>
      </MessageContainer>
    );
  }

  function renderError() {
    return (
      <MessageContainer>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <MessageText>
          <HighlightText color="coral">Error</HighlightText> sending
        </MessageText>
        <MessageActionButton onClick={resetResult}>Try Again</MessageActionButton>
      </MessageContainer>
    );
  }

  const submitted = !!result;
  const error = submitted && result.error;
  const success = submitted && result.success;

  return (
    <Modal>
      {!submitted && renderForm()}
      {error && renderError()}
      {success && renderSuccess()}
    </Modal>
  );
}

LeaveCVModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export { LeaveCVModal };
