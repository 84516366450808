import React from 'react';

export const IArrowRhomb = (
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.4" width="39.598" height="39.598" transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.570312 28)" fill="white" />
    <rect x="0.707107" width="38.598" height="38.598" transform="matrix(0.707107 0.707107 0.707107 -0.707107 0.777419 27.5)" stroke="white" />
    <path d="M20.2617 27.505H36.5959" stroke="white" />
    <path d="M30.1621 21.5653L36.5968 27.505L30.1621 32.9497" stroke="white" />
  </svg>
);
