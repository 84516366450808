import styled from 'styled-components';
import { animated } from 'react-spring';

export const HeaderContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 0 64px 0;
`;

export const HeaderMobile = styled.div`
    font-size: 16px;
    padding-top: 8px;  
`;

export const HeaderMobileContainer = styled.div`
    padding: 20px 0 20px 0;
`;

export const AnimatedHeader = styled(animated.div)`
    color: white;
    font-style: bold;
    text-align: left;
    margin-top: 24px;
`;

export const ScrollCaptionContainer = styled(animated.div)`
    display: flex;
    flex-direction: column;
`;

export const ScrollCaption = styled.div`
    height: 100%;
    border-left: 2px solid #ABB2BF;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    color: #ABB2BF;
    padding-left: 8px;
    display: flex;
    align-items: center;
`;

export const ScrollDecorative = styled.div`
    height: 100%;
    border-left: 2px solid #4F5562;
`;
