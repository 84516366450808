import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ReviewSectionStyled, ReviewSectionInner,
  ReviewSectionWrapHeader, BigImgSlider,
} from './styles';
import {
  Container as MobileContainer,
  Slider,
  ReviewSection as MobileSection,
  GreyLine,
} from '../ReviewSectionMobile/styles';
import { Container } from '../../../../ui';
import ReviewSliderItem from './ReviewSliderItem';

import ReviewSliderItemMobile from '../ReviewSectionMobile/ReviewSliderItem';

const speedSliderAnimation = 1000;
const sliderConfig = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: speedSliderAnimation,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export function ReviewSection({ reviews }) {
  const slider = useRef(null);
  const isMobile = window.innerWidth <= 768;

  function slideBack() {
    slider.current.slickPrev();
  }

  function slideForward() {
    slider.current.slickNext();
  }

  const sliderItems = reviews.map((item, index) => (
    <ReviewSliderItem
      key={index}
      onPreviousButtonClick={slideBack}
      onNextButtonClick={slideForward}
      {...item}
    />
  ));

  return (
    <ReviewSectionStyled>
      <Container>
        <ReviewSectionInner>
          <BigImgSlider ref={slider} speedAnimation={speedSliderAnimation} {...sliderConfig}>
            {sliderItems}
          </BigImgSlider>
        </ReviewSectionInner>
      </Container>
    </ReviewSectionStyled>
  );
}

ReviewSection.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
  })),
};
