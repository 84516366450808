import React from 'react';

import PropTypes from 'prop-types';
import {
  BigImgSliderItem, BigImgSliderItemInfo,
  BigImgSliderItemName, BigImgSliderItemLocation,
  BigImgSliderItemImg, BigImgSliderItemWrapDescription,
  BigImgSliderItemDescription, BigImgSliderBttnPrev,
  BigImgSliderBttnNext, BigImgSliderBttns,
} from './styles';
import braces from '../../../../assets/img/braces.png';

function ReviewSliderItem({ name, location, description, who, img, onPreviousButtonClick, onNextButtonClick }) {
  return (
    <BigImgSliderItem>
      <BigImgSliderItemWrapDescription>
        <BigImgSliderItemDescription>
          <div className="braces">
            <img src={braces} />
          </div>
          {description}
        </BigImgSliderItemDescription>
        <BigImgSliderBttns>
          <BigImgSliderBttnPrev onClick={onPreviousButtonClick}>
            <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 6.73926L0.999999 6.73926" stroke="white" />
              <path d="M8.08984 13L0.998934 6.73913L8.08984 1" stroke="white" />
            </svg>
          </BigImgSliderBttnPrev>
          <BigImgSliderBttnNext onClick={onNextButtonClick}>
            <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7.26074L18 7.26074" stroke="white" />
              <path d="M10.9102 1L18.0011 7.26087L10.9102 13" stroke="white" />
            </svg>
          </BigImgSliderBttnNext>
        </BigImgSliderBttns>
        <div className="who">
          <div className="who-name">
            {name}
          </div>
          <div className="who-location">
            {location}
          </div>
        </div>
      </BigImgSliderItemWrapDescription>
    </BigImgSliderItem>
  );
}

ReviewSliderItem.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onPreviousButtonClick: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  img: PropTypes.string.isRequired,
};

export default ReviewSliderItem;
