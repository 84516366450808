import React from 'react';

import { StateExtractors } from '../Forms';
import * as Validators from '../Forms/validators';

export default [
  {
    id: 'kind_of_activity',
    type: 'text',
    label: 'What do you do (project description, duration – long-term/short-term)?',
    getState: StateExtractors.htmlInputElement,
    mobileLabelTop: '4px',
  },
  {
    id: 'contact_person',
    type: 'text',
    label: 'A person with whom we can contact about the project',
    getState: StateExtractors.htmlInputElement,
  },
  {
    id: 'perspectives',
    type: 'text',
    label: 'Company/product perspectives',
    getState: StateExtractors.htmlInputElement,
    mobileLabelTop: '24px',
  },
  {
    id: 'what_to_do',
    type: 'text',
    label: 'What to do on the project?',
    getState: StateExtractors.htmlInputElement,
    mobileLabelTop: '24px',
  },
  {
    id: 'team_size',
    type: 'text',
    label: 'The size and structure of the team? How many testers/developers?',
    getState: StateExtractors.htmlInputElement,
  },
  {
    id: 'project_duration',
    type: 'text',
    label: 'How many years is the project? What is the stage of development?',
    getState: StateExtractors.htmlInputElement,
  },
  {
    id: 'specialists_location',
    type: 'text',
    label: 'Where are the people who make technical decisions located?',
    getState: StateExtractors.htmlInputElement,
  },
  {
    id: 'privacy_policy',
    type: 'checkbox',
    label: <span>I have read the <a href="/privacy-policy" target="blank">privacy policy</a> and agree to it</span>,
    getState: StateExtractors.direct,
    required: true,
    middleware: [
      Validators.requiredCheckbox,
    ],
  },
  {
    id: 'news_subscription',
    type: 'checkbox',
    label: 'Yes, I want to receive email newsletters with special promotions. I can unsubscribe at any time',
    getState: StateExtractors.direct,
  },
];
