import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${(value || '')}${expires}; path=/`;
}
function getCookie(name) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999;`;
}

const CookiePopupStyled = styled.div`

    position: fixed;
    bottom: 0;
    right: 40px;
    left: 40px;
    min-height: 40px;
    background:
    #282C34;
    border-radius: 3px 3px 0px 0px;
    vertical-align: c;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    font-family: Source Code Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: #ABB2BF;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        height: 160px;
        flex-direction: column; 
        align-items: flex-start;
        padding-top: 16px;
        padding-bottom: 20px;
        font-size: 12px;
        line-height: 150%;
 
    };

    a {
        color:  #56B6C2;
    }

    .ok {
        width: 85px;
        height: 24px;
        left: 1131px;
        top: 638px;

        @media (max-width: ${p => p.theme.breakpoints.tablet}) {
            width: 150px;
            background: #3B3F48;
            height: 32px;
            padding-top: 5px;
        };

        background: #285257;
        border-radius: 3px;
        font-family: Source Code Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        padding-top: 3px;
        color: #56B6C2;
        cursor: pointer;
    }

`;


const CookiePopup = () => {
  const [ok, setOk] = useState(getCookie('we_use_cookies_ok'));

  if (ok) {
    return null;
  }
  return (
    <CookiePopupStyled>
      <div>
          We use cookies so we can offer you the best service. By using our website you agree to the use of cookies. <a href="/privacy-policy" target="_blank">More information</a>
      </div>
      <div
        className="ok"
        onClick={() => {
          setCookie('we_use_cookies_ok', 1, 365);
          setOk(true);
        }}
      >
          Ok
      </div>
    </CookiePopupStyled>
  );
};

CookiePopup.propTypes = {};

export default CookiePopup;
