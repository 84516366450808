/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import Card from '../Card';
import {
  Container,
  TechnologiesHeader,
  TechnologiesTitle,
  WebRow,
  MobileRow,
  MultipleCardsRow,
  MultipleCardsWrapper,
  FancyBorder,
} from './styles';
import CardsRow from '../CardsRow';
import useWindowSize from '../../../../hooks/useWindowSize';

function TechnologiesSection({ technologies }) {
  const [windowWidth] = useWindowSize();
  const isMobile = windowWidth <= 600;
  function renderCard(card, indent) {
    const { name, logo, title } = card;
    return (
      <Card
        style={{ marginRight: `${indent}px` }}
        imageStyle={{
          width: '70px',
          height: '70px',
        }}
        key={name}
        name={name}
        image={logo}
        title={title}
      />
    );
  }

  function renderTechnology(config, index, self) {
    const indent = index !== self.length - 1 ? 100 : 0;
    if (!config.multiple) {
      return renderCard(config, indent);
    }

    const { cards, title } = config;
    return (
      <MultipleCardsWrapper
        key={cards.map(c => c.name).join('_')}
        style={{ marginRight: `${indent}px` }}
      >
        {title}
        <FancyBorder />
        <MultipleCardsRow>
          {cards.map((card, i, s) => {
            const localIndent = i !== s.length - 1 ? 38 : 0;
            return renderCard(card, localIndent);
          })}
        </MultipleCardsRow>
      </MultipleCardsWrapper>
    );
  }

  return (
    <Container>
      <div>
        <TechnologiesHeader>With a hefty list of projects under our belt, we have an extensive range of services and capabilities to cater to your unique needs. </TechnologiesHeader>
        <TechnologiesTitle>Our end-to-end custom software development can’t be beat, and part of Team Harbour’s strength comes from not being limited by a particular language, platform, or database. Take a look at a summary of our stack capabilities.</TechnologiesTitle>
      </div>
      <WebRow>
        <CardsRow
          title="Front-end:"
          cards={technologies.frontEnd}
          renderCard={renderTechnology}
        />
        <CardsRow
          rows={isMobile ? 2 : 1}
          indent
          title="Back-end:"
          cards={technologies.backEnd}
          renderCard={renderTechnology}
        />
      </WebRow>
      <MobileRow>
        <CardsRow
          rows={isMobile ? 3 : 1}
          title="Mobile:"
          cards={technologies.mobile}
          renderCard={renderTechnology}
        />
      </MobileRow>
    </Container>
  );
}

TechnologiesSection.propTypes = {
  technologies: PropTypes.shape({
    frontEnd: PropTypes.array.isRequired,
    backEnd: PropTypes.array.isRequired,
    mobile: PropTypes.array.isRequired,
  }).isRequired,
};

export default TechnologiesSection;
