import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useWindowSize from '../../hooks/useWindowSize';
import ReactPageScroller from './ReactPageScroller';

function PageScroll({ disabled, children, ...props }) {
  const [ctrlDown, setCtrlDown] = useState(false);
  const [windowWidth] = useWindowSize();

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
  }, []);

  function handleKeyDown(e) {
    if (e.ctrlKey) {
      setCtrlDown(true);
    }
  }

  function handleKeyUp(e) {
    if (e.keyCode === 17) {
      setCtrlDown(false);
    }
  }

  const blockScroll = {
    blockScrollDown: ctrlDown,
    blockScrollUp: ctrlDown,
  };

  if (disabled || windowWidth <= 600) {
    return children;
  }

  return (
    <ReactPageScroller {...blockScroll} {...props}>
      {children}
    </ReactPageScroller>
  );
}

PageScroll.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PageScroll;
