import React from 'react';
import PropTypes from 'prop-types';

import Intro from '../../../../components/Intro';
import joinStrings from '../../../../utils/joinStrings';
import { useModalState } from '../../../../hooks';
import { LeaveCVModal } from '../../../../components/LeaveCVModal';

import { ActionsSeparator, ActionsContainer, LeaveCVButton, CheckOutJobsButton } from './styles';
import { IntroSectionHeader, IntroSectionTitle } from '../../../../components/Intro/styles';

import strings from './strings';

const header = (
  <IntroSectionHeader>
    {strings.header.length ? joinStrings(strings.header, <br key="br" />) : strings.header}
  </IntroSectionHeader>
);
const title = <IntroSectionTitle>{strings.title}</IntroSectionTitle>;

function IntroSectionComponent() {
  const leaveCV = useModalState(false);

  function renderActions() {
    return (
      <ActionsContainer>
        {leaveCV.opened && <LeaveCVModal onClose={leaveCV.toggle} />}
        <CheckOutJobsButton href="https://jobs.dou.ua/companies/team-harbour/vacancies/" target="_blank">
          <div>Check out current jobs</div>
        </CheckOutJobsButton>
        <ActionsSeparator>or</ActionsSeparator>
        <LeaveCVButton onClick={leaveCV.toggle}>
          Leave your CV here
        </LeaveCVButton>
      </ActionsContainer>
    );
  }

  return <Intro {...{ header, title, renderActions }} />;
}

IntroSectionComponent.propTypes = {
  onLeaveCVClick: PropTypes.func.isRequired,
};

export const IntroSection = IntroSectionComponent;
