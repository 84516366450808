import styled from 'styled-components';

export const CardContainer = styled.div`
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
`;

export const Image = styled.img`
`;

export const Title = styled.span`
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    margin-top: 24px;
`;
