import React from 'react';
import PropTypes from 'prop-types';

import { TextInput } from './TextInput';
import { InputLabel } from './InputLabel';
import { InputField } from './InputField';
import Checkbox from './Checkbox';

import { FormFieldTypes } from '../../constants';
import { FileInput } from './FileInput';
import { TextAreaMobile } from './TextArea/styles';

function FormFieldFabric({ type, id, label, onChange, getState, middleware, ...inputProps }) {
  const handleChange = onChange(getState, middleware);

  switch (type) {
    case FormFieldTypes.EMAIL:
      inputProps.pattern = '.+@.+\\...+';
    // eslint-disable-next-line no-fallthrough
    case FormFieldTypes.TEXT:
      return (
        <InputField>
          <TextInput
            {...inputProps}
            type={type}
            id={id}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            onChange={handleChange}
          />
          <InputLabel htmlFor={id}>{label}</InputLabel>
        </InputField>
      );
    case FormFieldTypes.TEXTAREA:
      return (
        <TextAreaMobile
          {...inputProps}
          type={type}
          id={id}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          onChange={handleChange}
        />
      );
    case FormFieldTypes.FILE:
      return (
        <InputField>
          <FileInput
            {...inputProps}
            id={id}
            onChange={handleChange}
          />
          <InputLabel htmlFor={id}>{label}</InputLabel>
        </InputField>
      );
    case FormFieldTypes.CHECKBOX:
      return (
        <Checkbox
          id={id}
          label={label}
          onChange={handleChange}
          {...inputProps}
        />
      );
    default:
      return null;
  }
}

FormFieldFabric.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  middleware: PropTypes.arrayOf(
    PropTypes.func,
  ),
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired,
};

FormFieldFabric.defaultProps = {
  middleware: [],
};

export default FormFieldFabric;
