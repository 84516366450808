import styled from 'styled-components';

export const ChecklistItemStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        font-size: 12px;
        line-height: 180%;
    }
`;

export const CheckImage = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 16px;
`;
