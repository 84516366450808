import React from 'react';
import PropTypes from 'prop-types';

import { useModalState } from '../../../hooks/index.js';

import ContactsAccordion from './ContactsAccordion.js';
import LeaveContactModal from './LeaveContactModal';
import {
  ContactUsButton,
} from './styles';

function FooterMobile({ contacts }) {
  const contactUs = useModalState(false);
  return (
    <div style={{ padding: '90px 10px 138px 10px' }}>
      <ContactsAccordion locations={contacts} />
      <ContactUsButton onClick={contactUs.toggle}>Write To Me</ContactUsButton>
      {
        contactUs.opened && (
          <LeaveContactModal onClose={contactUs.toggle} />
        )
      }
    </div>
  );
}

FooterMobile.propTypes = {
  contacts: PropTypes.array.isRequired,
};

export default FooterMobile;
