import React from 'react';

import { useModalState } from '../../../../hooks';
import { EmptyButton } from '../../../../ui';
import {
  Header,
  Title,
  ExpertiseScreen,
  ExpertiseSectionContent,
} from './styles';

import expertise from '../../../../data/expertiseSlider';

function ExpertiseSectionMobile() {
  const description = useModalState(false);

  return (
    expertise.map((item, index) => (
      <ExpertiseScreen key={index} bg={item.img}>
        <ExpertiseSectionContent>
          <Header>
            {item.header}
          </Header>
          <Title>
            {description.opened ? item.text : item.title}
          </Title>
          <EmptyButton onClick={description.toggle}>
            {description.opened ? 'Less Info' : 'More Info'}
          </EmptyButton>
        </ExpertiseSectionContent>
      </ExpertiseScreen>
    ))
  );
}

export default ExpertiseSectionMobile;
