import styled from 'styled-components';
import Slider from 'react-slick';
import { FullPageSection } from '../../../../ui';

export const BigImgSliderBttnPrev = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #191919;
    cursor: pointer;
    svg{
        width: 1em;
        height: 1em;
        font-size: 22px;
    }
`;

export const BigImgSliderBttnNext = styled(BigImgSliderBttnPrev)`
    svg{
        font-size: 23px;
        margin-top: -1.5px;
    }
`;

export const BigImgSliderBttns = styled.div`
    display: flex;
    align-items: center;
    ${BigImgSliderBttnPrev}{
        margin-right: 5px;
    }
    grid-area: buttons;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        margin-top: 30px;
    };
`;
export const BigImgSliderItemDescription = styled.p`
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 180%;
    grid-area: description;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        font-weight: 500;
        font-size: 16px;
    }
`;

export const BigImgSliderItemWrapDescription = styled.div`
    display: grid;
    grid-template-areas: 
        "description buttons"
        "who how-many"
    ;
    padding-left: 155px;
    ${BigImgSliderItemDescription}{
        max-width: 678px;
    };
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        grid-template-areas: 
            "description"
            "who"
            "how-many"
            "buttons"
        ;
    }
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding-left: 0;
    };
    .who {
        grid-area: who;
        font-family: Source Code Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 180%;
        @media (max-width: ${p => p.theme.breakpoints.tablet}) {
            margin-top: 30px;
        };
        
        @media (max-width: ${p => p.theme.breakpoints.tablet}) {
            font-size: 10px;
        }
        .who-location {
            @media (max-width: ${p => p.theme.breakpoints.tablet}) {
                margin-top: 10px;
            };
        }

    }
    .how-many {
        grid-area: how-many;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 104.5%;
        /* or 25px */
        @media (max-width: ${p => p.theme.breakpoints.tablet}) {
            font-size: 10px;
        }
    }
`;

export const BigImgSliderItemInfo = styled.div`
    padding: 24px 34px;
    background-color: ${props => props.theme.colors.coral};
`;

export const BigImgSliderItemImg = styled.div`
    background: url(${props => props.bg}) center center no-repeat;
    background-size: cover;
    position: relative;
    ${BigImgSliderItemInfo}{
        width: 400px;
        height: 115px;
        position: absolute;
        right: -85px;
        top: -50px;
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding-left: 0;
        ${BigImgSliderItemInfo}{
            right: -220px;
        };
    };
`;

export const BigImgSliderItemLocation = styled.div`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    color: #fff;
`;

export const BigImgSliderItemName = styled.div`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: bold;
    font-size: 18px;
    line-height: 180%;
    color: #000;
`;

export const BigImgSliderItem = styled.div`
    display: flex !important;
    outline: none;
    .braces {
        margin-bottom: 40px;
    }
    ${BigImgSliderItemImg}{
        height: 680px;
        max-width: 550px;
        flex: 550px;
    };
    ${BigImgSliderItemWrapDescription}{
        max-width: calc(100% - 550px);
        flex: calc(100% - 550px);
    };
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        ${BigImgSliderItemImg}{
            height: 520px;
            max-width: 420px;
            flex: 520px;
        };
        ${BigImgSliderItemWrapDescription}{
            max-width: 1200px;
            flex: calc(100% - 420px);
        };
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        flex-direction: column;
        ${BigImgSliderItemImg}{
            margin-top: 50px;
        }
        ${BigImgSliderItemWrapDescription}{
            max-width: 100%;
        }
    };
`;

export const BigImgSlider = styled(Slider)`
    .slick-list{
        overflow: visible; 
    };
    .slick-slide{
        opacity: 0;
        transition: opacity ${props => `${props.speedAnimation / 1000}s`};
        &[aria-hidden="false"]{
            opacity: 1;
        }
        &.slick-current + .slick-slide{
            opacity: 1;
            transition: none;
        }
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        .slick-list{
            overflow-x: hidden; 
        };
    };
`;

export const ReviewSectionWrapHeader = styled.div`
    width: 102px;
    height: 701px;
    svg{
        width: 100%;
        height: 100%;
    }
`;

export const ReviewSectionInner = styled.div`
    position: relative;
    padding-left: 80px;
    ${ReviewSectionWrapHeader}{
        position: absolute;
        left: 0;
        bottom: 0;
    }
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        padding-left: 60px;
        ${ReviewSectionWrapHeader}{
            width: 82px;
            height: 558px;
        }
    };
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        padding-left: 0px;
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        ${ReviewSectionWrapHeader}{
            top: 10px;
            bottom: initial; 
        }
    };
    
`;

export const ReviewSectionStyled = styled(FullPageSection)`
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        &::before {
            position: absolute;
            width: 671px;
            height: 102px;
            left: 0px;
            top: 275px;
            font-family: Source Code Pro;
            font-style: normal;
            font-weight: 900;
            font-size: 97.6px;
            line-height: 104.5%;
            /* identical to box height, or 102px */
            letter-spacing: -0.03em;
            color: rgba(255, 255, 255, 0.09);
        }
    }

    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        flex-direction: column;
        justify-content: center;
        padding-top: 100px;
        &::before{
            width: 50%;
        }
    };
`;
