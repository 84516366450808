/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Button } from '../../../../ui';
import {
  MessageSectionStyled, MessageSectionHeader,
  MessageSectionText, LogoSlider,
  LogoSliderItem, LogoSliderItemImg,
  MobileContainer,
} from './styles';

import partnersLogo from '../../../../data/partnersLogo';

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: window.innerWidth <= 768 ? 3.5 : 5,
  slidesToScroll: 1,
};

export const MessageSection = ({ contactUsModalState }) => {
  const sliderItems = partnersLogo.map(
    (item, key) => (
      <LogoSliderItem key={key}>
        <LogoSliderItemImg src={item.logo} alt={item.name} />
      </LogoSliderItem>
    ),
  );

  return (
    <MessageSectionStyled>
      <Container>
        <MobileContainer>
          <MessageSectionHeader>
            Let&lsquo;s make IT together
          </MessageSectionHeader>
          <MessageSectionText>
            {/* eslint-disable-next-line max-len */}
          Streamline your workflows and communication, improve customer service, and let Team Harbour guide your company through its digital transformation. We have extensive experience in a broad spectrum of technologies and a vast network of professionals to find the optimal solution for your business—all for the best price.
          </MessageSectionText>
          <LogoSlider {...sliderSettings}>
            {sliderItems}
          </LogoSlider>
          <Button onClick={contactUsModalState.toggle}>
            Get In Touch
          </Button>
        </MobileContainer>
      </Container>
    </MessageSectionStyled>
  );
};
