import React from 'react';

import { StateExtractors } from '../Forms';
import * as Validators from '../Forms/validators';

function isFileInput(state) {
  return { ...state, attachment: true };
}

export default [
  {
    id: 'name',
    type: 'text',
    label: 'Name',
    getState: StateExtractors.htmlInputElement,
  },
  {
    id: 'email',
    type: 'email',
    label: 'Email',
    getState: StateExtractors.htmlInputElement,
    middleware: [
      Validators.email,
    ],
  },
  {
    id: 'skillset',
    type: 'text',
    label: 'Occupation / Skillset',
    getState: StateExtractors.htmlInputElement,
  },
  {
    id: 'CV',
    type: 'file',
    label: 'Attach CV file',
    getState: StateExtractors.direct,
    middleware: [
      isFileInput,
    ],
  },
  {
    id: 'portfolio',
    type: 'text',
    label: 'Link to portfolio or Linkedin (optional)',
    getState: StateExtractors.htmlInputElement,
  },
  {
    id: 'tell_us_smth',
    type: 'text',
    label: 'Tell us something (optional)',
    getState: StateExtractors.htmlInputElement,
  },
  {
    id: 'privacy_policy',
    type: 'checkbox',
    label: <span>I have read the <a href="/privacy-policy" target="blank">privacy policy</a> and agree to it</span>,
    getState: StateExtractors.direct,
    required: true,
    middleware: [
      Validators.requiredCheckbox,
    ],
  },
  {
    id: 'news_subscription',
    type: 'checkbox',
    label: 'Yes, I want to receive email newsletters with special promotions. I can unsubscribe at any time',
    getState: StateExtractors.direct,
  },
];
