import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DetailSliderItemStyled, DetailSliderItemInner,
  DetailSliderItemHeader, DetailSliderItemTitle,
  DetailSliderItemText,
} from './styles';
import { Container, Button, OpacityButton } from '../../../../ui';

export const DetailSliderItem = ({ data, onBack }) => {
  const [translation, setTranslation] = useState(-80);

  useEffect(() => {
    setTranslation(0);
  }, []);

  return (
    <DetailSliderItemStyled bg={data.img} style={{ transform: `translateY(${translation})` }}>
      <Container bg={data.img}>
        <DetailSliderItemInner>
          <OpacityButton onClick={onBack}>
            To preview
          </OpacityButton>
          <DetailSliderItemHeader color={data.headerColor}>
            {data.header}
          </DetailSliderItemHeader>
          <DetailSliderItemTitle>
            {data.title}
          </DetailSliderItemTitle>
          <DetailSliderItemText>
            {data.text}
          </DetailSliderItemText>
          <Button>
            Get In Touch
          </Button>
        </DetailSliderItemInner>
      </Container>
    </DetailSliderItemStyled>
  );
};

DetailSliderItem.propTypes = {
  data: PropTypes.object.isRequired,
  onNextSlide: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};
