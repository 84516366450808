import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        ${p => (p.indent ? `
            padding-top: 64px;
            ${CardsContainer} {
                overflow-x: auto;
            }
        ` : '')}
        width: 100%;
    }
`;

export const Title = styled.div`
    color: white;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        font-size: 18px;
        line-height: 23px;
    }
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        margin-top: 16px;
        padding-bottom: 20px;
        justify-content: space-around;
    }
`;
