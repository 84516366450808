import React from 'react';
import PropTypes from 'prop-types';

import { CardContainer, Image, Title } from './styles';

function Card({ title, image, name, style, imageStyle }) {
  return (
    <CardContainer style={style}>
      <Image
        style={imageStyle}
        src={image}
        alt={name}
      />
      {title && <Title>{title}</Title>}
    </CardContainer>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  imageStyle: PropTypes.object,
};

export default Card;
