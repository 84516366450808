/* eslint-disable */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useMemoOne } from 'use-memo-one';

const portalRoot = document.querySelector('#portal-root');

function Portal({ children }) {
  const el = useMemoOne(() => document.createElement('div'), []);

  useEffect(() => {
    portalRoot.appendChild(el);
    return () => {
      portalRoot.removeChild(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return createPortal(children, el);
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Portal;
