import styled from 'styled-components';

import marker from '../../../assets/img/location_marker.svg';
import { Button, LinkButton } from '../../../ui';

export const LocationHeader = styled.div`
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 180%;
    letter-spacing: 0.04em;

    display: flex;
    justify-content: space-between;
`;

export const AccordionContainer = styled.div`
    display: flex;
    min-height: 100%;
    width: 100%;
    flex-direction: column;
`;

export const ContactsLocationContainer = styled.div``;

export const CitiesContainer = styled.div`
    padding-bottom: 24px
`;

export const CityContainer = styled.div``;

export const CityHeader = styled.div`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 180%;
    /* or 32px */

    padding: 12px 2px;

    display: flex;
    align-items: center;

    color: #E5C07B;
`;

export const CityAddress = styled.div`
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: calc(180%);
    padding: 2px 0;
`;

export const Arrow = styled.div`
    background: url(${p => p.bg}) center center no-repeat;
    width: 24px;
    height: 24px;
`;

export const MapContainer = styled.div`
    margin-top: 16px;
    width: 100%;
    height: 132px;
    padding-bottom: 24px;
    position: relative;
    cursor: pointer;

    div {
        position: absolute;
    }
`;

export const MapBackground = styled.div`
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-color: black;
    opacity: 0.5;
`;

export const MapMarker = styled.div`
    width: 28px;
    height: 42px;

    background: url(${marker}) center center no-repeat;
    background-size: 100% 100%;
    transform: translate(0, -50%);
`;

export const MarkerAbsoluteCenter = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
`;

export const CancelButton = styled(LinkButton)`
    color: black;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2em;
    padding-left: 5%;
`;

export const FormHeader = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
`;

export const ContactUsButton = styled(Button)`
    width: 100%;
    background-color: white;
    color: black;
    border-color: white;

    &:hover {
        color: white;
    }
`;

export const MessageContainer = styled.div``;
export const MessageText = styled.div``;
