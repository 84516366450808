import React from 'react';
import PropTypes from 'prop-types';

import { FileItemStyled, RemoveFileButton } from './styles';

function FileItem({ name, index, onRemove }) {
  function handleRemoveButtonClick(event) {
    onRemove(index);
    event.stopPropagation();
  }

  return (
    <FileItemStyled>
      {name}&nbsp;
      <RemoveFileButton onClickCapture={handleRemoveButtonClick}>
        [delete]
      </RemoveFileButton>
    </FileItemStyled>
  );
}

FileItem.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  index: PropTypes.number,
};

export default FileItem;
