import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';

import { Routes } from '../constants';
import CareersPage from '../pages/CareersPage';
import AboutUsPage from '../pages/AboutUsPage';
import ServicesPage from '../pages/ServicesPage';
import PrivacyPage from '../pages/PrivacyPage';

export const Router = ({ contactUsModalState }) => (
  <BrowserRouter>
    <Switch>

      <Route
        exact
        path={Routes.HOME}
        component={() => <HomePage {...{ contactUsModalState }} />}
      />

      <Route
        exact
        path={Routes.CAREERS}
        component={CareersPage}
      />

      <Route
        exact
        path={Routes.ABOUT_US}
        component={AboutUsPage}
      />

      <Route
        exact
        path={Routes.PRIVACY}
        component={PrivacyPage}
      />

      <Route
        exact
        path={Routes.SERVICES}
        component={() => <ServicesPage {...{ contactUsModalState }} />}
      />

    </Switch>
  </BrowserRouter>
);

Router.propTypes = {
  contactUsModalState: PropTypes.object.isRequired,
};
