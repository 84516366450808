export const theme = {
  colors: {
    coral: '#E06C75',
    olivine: '#98C379',
    fountainBlue: '#56B6C2',
    cadetBlue: '#ABB2BF',
    harvestGold: '#E5C07B',
    shark: '#282C34',
    charade: '#2C313C',
  },
  fonts: {
    sourceCodePro: '\'Source Code Pro\', monospace',
    poppins: '\'Poppins\', sans-serif',
  },
  breakpoints: {
    desktop: '1599px',
    laptop: '1279px',
    tablet: '767px',
    mobile: {
      L: '425px',
      M: '373px',
      S: '320px',
    },
  },
};
