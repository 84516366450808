import React from 'react';
import PropTypes from 'prop-types';

import { FullPage } from '../../ui';
import IntroSection from './components/IntroSection';
import { Footer } from '../../components/Footer';
import ReasonsSection from './components/ReasonsSection';
import AccordionSection from './components/AccordionSection';
import OutsourceWithUsSection from './components/OutsourceWithUsSection';

function ServicesPage({ contactUsModalState }) {
  return (
    <FullPage scrollable>
      <IntroSection />
      <OutsourceWithUsSection />
      <ReasonsSection contactUsModalState={contactUsModalState} />
      <AccordionSection />
      <Footer />
    </FullPage>
  );
}

ServicesPage.propTypes = {
  contactUsModalState: PropTypes.object.isRequired,
};

export default ServicesPage;
