export default file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve({
    filename: file.name,
    content: reader.result.replace(/^data:.+;base64,/, ''),
    encoding: 'base64',
  });
  reader.onerror = error => reject(error);
});
