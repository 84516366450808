import styled from 'styled-components';
import { HeaderStyled } from './components/Header/styles';

export const AppStyled = styled.div`
    ${HeaderStyled}{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
    };
`;
