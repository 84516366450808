import React from 'react';
import PropTypes from 'prop-types';

import {
  Indicator,
  AccordionItemContainer,
  AccordionItemDescription,
  AccordionItemTitle,
  AccordionItemContent,
} from './styles';
import { useModalState } from '../../../../hooks';

function AccordionItem({ title, description, onClick, expanded }) {
  const { opened, toggle } = useModalState(expanded);
  return (
    <AccordionItemContainer onClick={toggle} opened={opened}>
      <AccordionItemContent>
        <AccordionItemTitle>
          {title}
        </AccordionItemTitle>

        {opened && (
        <AccordionItemDescription>
          {description}
        </AccordionItemDescription>
        )}
      </AccordionItemContent>
      <Indicator>
        {`<${opened ? '/' : '+'}>`}
      </Indicator>
    </AccordionItemContainer>
  );
}

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
};

export default AccordionItem;
