export default [
  {
    country: 'USA',
    opened: true,
    cities: [
      {
        city: 'Cincinnati',
        address1: '8180 Corporate Park Drive,',
        address2: 'Ste 140, Cincinnati, OH 45242',
        phone: '+1 561 612 1472',
        lat: 39.248160,
        lng: -84.363190,
      },
      {
        city: 'Chicago',
        address1: '444 N. Wabash Ave,',
        address2: 'Suite 210, Chicago, IL 60611',
        phone: '+1 888 939 7587',
        lat: 41.896730,
        lng: -87.621560,
      },
    ],
  },
  {
    country: 'Middle East',
    cities: [
      {
        city: 'Dubai',
        address1: 'DWC Business Center 1st Floor,',
        address2: 'Dubai World Center, Dubai, UAE',
        phone: '+971 56 829 0910',
        lat: 25.494197,
        lng: 55.547684,
      },
    ],
  },
  {
    country: 'Europe',
    cities: [
      {
        city: 'Riga',
        address1: 'Krisjana Barona iela 32-1,',
        address2: 'Riga LV-1011 Latvia',
        phone: '+371 26 382287',
        lat: 56.952480,
        lng: 24.124250,
      },
      {
        city: 'Genève',
        address1: 'rue de Cendrier 15,',
        address2: 'Genève, Switzerland',
        phone: '+41 79 906 01 77',
        lat: 46.207400,
        lng: 6.144660,
      },
      {
        city: 'Kyiv',
        address1: '39 Hoholivska Street,',
        address2: 'Kyiv, Ukraine',
        phone: '+380503343331',
        lat: 50.4613489,
        lng: 30.5072836,
      },
    ],
  },
];
