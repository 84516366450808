import styled from 'styled-components';

export const EmptyButtonStyled = styled.button`
    width: 160px;
    height: 36px;
    text-align: center;
    border: 2px solid #FFFFFF;
    border-radius: 3px;
    color: #fff;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    background: none;
    cursor: pointer;
    transition: all .4s;
    &:hover{
        color: ${props => props.theme.colors.fountainBlue};
        border-color: ${props => props.theme.colors.fountainBlue};
    }
`;
