import React from 'react';

import IntroSection from './components/IntroSection';
import TechnologiesSection from './components/TechnologiesSection';
import { Footer } from '../../components/Footer';
import { FullPage } from '../../ui';
import { partnersLogo as partners } from '../../data';
import technologies from '../../data/technologies';

function AboutUsPage() {
  return (
    <FullPage scrollable>
      <IntroSection partners={partners} />
      <TechnologiesSection technologies={technologies} />
      <Footer />
    </FullPage>
  );
}

export default AboutUsPage;
