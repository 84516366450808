import React from 'react';
import PropTypes from 'prop-types';

import { Container } from '../../ui';
import {
  IntroSectionStyled,
  ColorTextWrap,
  ColorText,
  HeaderWrap,
  TitleWrap,
  ActionsContainer,
} from './styles';

import { theme } from '../../constants';

const { colors } = theme;

function Intro({ title, header, renderActions, contentContainerStyle, WrapperComponent }) {
  const Wrapper = WrapperComponent || IntroSectionStyled;
  return (
    <Wrapper>
      <Container style={contentContainerStyle}>
        <HeaderWrap>
          <ColorTextWrap>
            <ColorText color={colors.fountainBlue}>&lt;p</ColorText>
            &#160;
            <ColorText color={colors.cadetBlue}>class</ColorText>
            <ColorText color={colors.fountainBlue}>=&quot;</ColorText>
            <ColorText color={colors.coral}>lp-lead fade-in-bck in-view</ColorText>
            <ColorText color={colors.fountainBlue}>&quot;</ColorText>
            &#160;
            <ColorText color={colors.cadetBlue}>data-scroll</ColorText>
            <ColorText color={colors.fountainBlue}>=&quot;</ColorText>
            <ColorText color={colors.coral}>in</ColorText>
            <ColorText color={colors.fountainBlue}>&quot;&gt;</ColorText>
          </ColorTextWrap>
          {header}
          <ColorTextWrap>
            <ColorText color={colors.fountainBlue}>&lt;/p&gt;</ColorText>
          </ColorTextWrap>
        </HeaderWrap>
        <TitleWrap>
          <ColorTextWrap>
            <ColorText color={colors.fountainBlue}>&lt;p</ColorText>
            &#160;
            <ColorText color={colors.cadetBlue}>class</ColorText>
            <ColorText color={colors.fountainBlue}>=&quot;</ColorText>
            <ColorText color={colors.coral}>lp-sans</ColorText>
            <ColorText color={colors.fountainBlue}>&quot;&gt;</ColorText>
          </ColorTextWrap>
          {title}
          <ColorTextWrap>
            <ColorText color={colors.fountainBlue}>&lt;/p&gt;</ColorText>
          </ColorTextWrap>
        </TitleWrap>
        <ActionsContainer>
          {renderActions && renderActions()}
        </ActionsContainer>
      </Container>
    </Wrapper>
  );
}

Intro.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  renderActions: PropTypes.func,
  contentContainerStyle: PropTypes.object,
  WrapperComponent: PropTypes.func,
};

export default Intro;
