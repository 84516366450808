import styled from 'styled-components';
import Slider from 'react-slick';
import { DetailSliderItemStyled } from './components/DetailSliderItem/styles';

export const DetailSliderStyled = styled(Slider)`
    transition: all 1s ease-in;
    .slick-prev{
        display: none!important;
    };
    .slick-list, .slick-track{ 
        height: 100%
    }
    .slick-slide > div {
        height: 100%;
    }
    ${DetailSliderItemStyled}{
        height: 100%;
    }
`;
