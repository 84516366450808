import react from '../assets/img/technologies/react.svg';
import angular from '../assets/img/technologies/angular.svg';
import java from '../assets/img/technologies/java.svg';
import spring from '../assets/img/technologies/spring.svg';
import python from '../assets/img/technologies/python.svg';
import php from '../assets/img/technologies/php.svg';
import nodejs from '../assets/img/technologies/nodejs.svg';
import reactNative from '../assets/img/technologies/react_native.svg';
import kotlin from '../assets/img/technologies/kotlin.svg';
import swift from '../assets/img/technologies/swift.svg';
import objectiveC from '../assets/img/technologies/apple.svg';

export const frontEnd = [
  {
    logo: react,
    name: 'react',
    title: 'React',
  },
  {
    logo: angular,
    name: 'angular',
    title: 'Angular2',
  },
];

export const backEnd = [
  {
    logo: spring,
    name: 'spring',
    title: 'Java/Spring',
  },
  {
    logo: nodejs,
    name: 'nodejs',
    title: 'Node.js',
  },
  {
    logo: python,
    name: 'python',
    title: 'Python',
  },
  {
    logo: php,
    name: 'php',
    title: 'PHP',
  },
];

export const mobile = [
  {
    logo: reactNative,
    name: 'react_native',
    title: 'React Native',
  },
  {
    multiple: true,
    title: 'Android Native',
    cards: [
      {
        logo: java,
        name: 'java',
        title: 'Java',
      },
      {
        logo: kotlin,
        name: 'kotlin',
        title: 'Kotlin',
      },
    ],
  },
  {
    multiple: true,
    title: 'IOS Native',
    cards: [
      {
        logo: swift,
        name: 'swift',
        title: 'Swift',
      },
      {
        logo: objectiveC,
        name: 'objectiveC',
        title: 'Objective-C',
      },
    ],
  },
];

export default {
  frontEnd,
  backEnd,
  mobile,
};
