import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpertiseListItem from './ExpertiseListItem';
import TypingScreen from './TypingScreen';
import ExpertiseSectionMobile from '../ExpertiseSectionMobile';
import {
  ExpertiseSectionStyled,
  HorizontalList,
  MainContainer,
} from './styles';
import useWindowSize from '../../../../hooks/useWindowSize';

export const TypingAnimationSection = ({ startTyping }) => {
  const [typingDone, setTypingDone] = useState(false);
  const [windowWidth] = useWindowSize();

  function handleTypingFinish() {
    setTypingDone(true);
  }

  if (windowWidth <= 600) {
    return null;
  }

  return (
    <>
      <MainContainer>
        {!typingDone && (
          <TypingScreen
            speed={7}
            startTyping={startTyping}
            onFinish={handleTypingFinish}
          >
            End-to-End Custom Software Development
          </TypingScreen>
        )}
      </MainContainer>
    </>
  );
};

TypingAnimationSection.propTypes = {
  startTyping: PropTypes.bool.isRequired,
};
