import styled from 'styled-components';
import locationMarker from '../../assets/img/location_marker.svg';
import { FullPageSection, Container, Button } from '../../ui';

export const IframeTabsFromField = styled.input`
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    background: #E28D93;
    border-radius: 3px;
    border: 1px solid transparent;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 120%;

    &::placeholder {
        color: #FFFFFF;
    };

    &:focus {
        outline: none;
        border: 1px solid #FFFFFF
    };
`;

export const IframeTabsFrom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 32px;
    position: absolute;
    bottom: 15px;
    left: 30px;
    right: 30px;
    z-index: 1;
    background-color: ${props => props.theme.colors.coral}
    ${IframeTabsFromField}{
        margin-right: 30px;
    };
    ${Button}{
        width: 266px;
    };
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        flex-direction: column; 
        align-items: flex-start;
        padding: 15px 12px ; 
        ${IframeTabsFromField}{
            margin-right: 0px;
            margin-bottom: 15px;
        };  
    };
`;

// rename on abstract name
export const IframeTabsBodyItemPhone = styled.p`
    margin: 0;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    pointer-events: auto;
`;

// rename on abstract name
export const IframeTabsBodyItemAddress2 = styled.p`
    margin: 0;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    pointer-events: auto;
`;

// rename on abstract name
export const IframeTabsBodyItemAddress1 = styled.p`
    margin: 0;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    pointer-events: auto;
`;

// rename on abstract name
export const IframeTabsBodyItemCity = styled.h6`
    display: inline-block;
    position: relative;
    margin: 0;
    color: ${props => props.theme.colors.harvestGold};
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 125%;
    pointer-events: auto;
    z-index: 1;
    &::after{
        content: '';
        display: block;
        width: auto;
        height: 8px;
        background-color: ${props => props.theme.colors.fountainBlue};
        position: absolute;
        left: 0;
        bottom: 0;
        right: ${props => (props.active ? '0%' : '100%')};
        z-index: -1;
        opacity: ${props => (props.active ? 1 : 0)};
        transition: right .4s, opacity .4s;
    }
    &:hover{
        &::after{
            right: 0%;
            opacity: 1;
        }

    }
`;

export const IframeTabsBodyItem = styled.div`
    cursor: pointer;
    ${IframeTabsBodyItemCity}{
        margin-bottom: 24px;
    }
    ${IframeTabsBodyItemAddress1}{
        margin-bottom: 8px;
    }
    ${IframeTabsBodyItemAddress2}{
        margin-bottom: 8px;
    }
`;

export const IframeTabsBody = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    ${IframeTabsBodyItem}{
        margin-right: 75px;
        &:last-child{
            margin-right: 0;
        };
    };
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        flex-direction: column;
        ${IframeTabsBodyItem}{
            margin-bottom: 50px;
            &:last-child{
                margin-bottom: 0;
            }
        };
    };
`;

export const IframeTabsHeadersItem = styled.div`
    color: ${props => (props.active ? '#fff' : props.theme.colors.shark)};
    text-shadow: ${
  props => (!props.active ? (
    '-1px -1px 0 #fff,' +
            '1px -1px 0 #fff,' +
            '-1px 1px 0 #fff,' +
            '1px 1px 0 #fff'
  ) : 'none')
};
    font-family: ${props => props.theme.fonts.poppins};
    font-weight: bold;
    font-size: 32px;
    line-height: 110%;
    letter-spacing: 0.04em;
    pointer-events: auto;
    cursor: pointer;
    @supports(-webkit-text-stroke: 1px #fff){
        -webkit-text-stroke: ${props => (props.active ? '0px' : '1px #fff')};
        color: ${props => (props.active ? '#fff' : 'transparent')};
        text-shadow: none;
    }
`;

export const IframeTabsHeaders = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${IframeTabsHeadersItem}{
        margin-right: 40px;
        &:last-child{
            margin-right: 0;
        }
    }
`;
export const IframeTabsWrapHeaders = styled.div`

`;

export const IframeTabsWrapIframe = styled.div`
    position: relative;
`;

export const CardBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 10;
    background-color: #282C34; 
    opacity: 0.8;
`;

export const IframeTabsTitle = styled.p`
    color: ${props => props.theme.colors.coral};
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
`;

export const IframeTabsInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        justify-content: flex-start;
        ${IframeTabsWrapHeaders}{
            margin-bottom: 50px;
        }
    };
`;

export const IframeTabs = styled.div`
    position: relative;
    padding: 30px;
    padding-bottom: 220px;
    ${IframeTabsInner}{
        height: 100%;
        z-index: 1;
        pointer-events: none;
    }
    ${IframeTabsWrapIframe}{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    };
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        padding-bottom: 170px;
    };
`;

export const ContactsSectionHeader = styled.h2`
    margin: 0;
    margin-bottom: -22px;
    color: ${props => props.theme.colors.harvestGold};
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: 900;
    font-size: 78px;
    line-height: 104.5%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
`;

export const ContactsSectionInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 120px;
    ${IframeTabs}{
        height: 80%;
    }
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        padding-top: 75px;
        ${IframeTabs}{
            height: 89%;
        }
    };
`;

export const ContactsSectionStyled = styled(FullPageSection)`
    ${Container}{
        height: 100%;
    };
    ${ContactsSectionInner}{
        height: 100%;
    };
`;

export const MapMarker = styled.button`
    width: 52px;
    height: 78px;
    position: absolute;
    transform: translate(-50%, -100%);
    border: none;
    outline: none;
    background: ${() => `url(${locationMarker})`};
    &:focus {
        outline: none;
    };
`;

export const MapContainer = styled(Container)`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        max-width: 1200px;
    };
`;
