export default function pipe(...funcs) {
  if (!funcs.length) {
    return a => a;
  }

  if (funcs.length === 1) {
    return funcs[0];
  }

  return funcs.reduce((a, b) => (...args) => b(a(...args)));
}
