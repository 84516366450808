import styled from 'styled-components';

import { theme } from '../../../constants';
import doneLight from '../../../assets/img/done_light.svg';

export const CheckboxContainer = styled.span`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.fonts.poppins};
  font-size: 14px;
  line-height: 180%;
  color: #FFFFFF;
  font-style: normal;
  font-weight: normal;
  & a {
    color: ${theme.colors.fountainBlue};
    text-decoration: none;
  }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  width: 20px;
  cursor: pointer;
  height: 20px;
  background: ${props => (props.checked ? 'salmon' : 'papayawhip')};
  border-radius: 3px;
  transition: all 150ms;
  background: ${props => (props.checked ? `url(${doneLight}) no-repeat center` : '')};
  background-color: ${props => (props.checked ? '#E06C75' : '#4F5562')};
  margin-right: 12px;
`;
