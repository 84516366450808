import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../ui';
import Intro from '../../../../components/Intro';
import { IntroSectionHeader, IntroSectionTitle } from '../../../../components/Intro/styles';

import strings from './strings';

const header = <IntroSectionHeader>{strings.header}</IntroSectionHeader>;
const title = <IntroSectionTitle>{strings.title}</IntroSectionTitle>;

function IntroSection({ contactUsModalState }) {
  function renderActions() {
    return (
      <Button onClick={contactUsModalState.toggle}>
        Get In Touch
      </Button>
    );
  }

  return <Intro {...{ header, title, renderActions }} />;
}

IntroSection.propTypes = {
  contactUsModalState: PropTypes.object.isRequired,
};

export { IntroSection };
