import React from 'react';
import PropTypes from 'prop-types';

import AccordionItem from './AccordionItem';

import {
  Container,
} from './styles';

function Accordion({ items }) {
  return (
    <Container>

      {items.map((item, index) => (
        <AccordionItem
          key={index}
          {...item}
        />
      ))}
    </Container>
  );
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Accordion;
