import styled from 'styled-components';

import { theme } from '../../../constants';

const { cadetBlue, fountainBlue, coral } = theme.colors;

export const TextInput = styled.input`
    width: 100%;
    height: 50px;
    position: relative;
    padding-bottom: 16px;
    margin-top: 14px;
    padding-right: 52px;
    background: none;
    border: none;
    outline: none;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    border-bottom: 2px solid ${cadetBlue}; 

    &:focus {
        outline: none;
        border-bottom-color: ${fountainBlue}; 
    };
    &:hover {
        border-bottom-color: ${fountainBlue}; 
    };

    & ~ label {
        color: ${cadetBlue};
        top: 31px;
        @media (max-width: ${p => p.theme.breakpoints.tablet}) {
            top: ${props => (props.mobileLabelTop || '16px')};
        }
    }

    &:invalid {
        color: ${coral};
        border-bottom-color: ${coral};
        outline: none;
    }

    &:not([value=""]) ~ label,
    &:invalid ~ label,
    &:focus ~ label {
        top: 0px;
        font-size: 12px;
        font-style: regular;
        line-height: 13px;

        @media (max-width: ${p => p.theme.breakpoints.tablet}) {
            top: -10px;
        }
    }   
`;
