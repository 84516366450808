export const sendMail = async ({ subject, text, attachment }) => {
  try {
    const responce = await fetch('https://ucctcruz32.execute-api.eu-west-1.amazonaws.com/Prod/send', {
      method: 'POST',
      body: JSON.stringify({
        subject,
        text,
        attachment,
      }),
    });

    return Promise.resolve(responce.status === 200);
  } catch (e) {
    return Promise.reject(e);
  }
};
