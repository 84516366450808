import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../ui';
import useWindowSize from '../../../../hooks/useWindowSize';
import ChecklistItem from './components/ChecklistItem';
import {
  Header,
  Checklist,
  Image,
  ContentContainer,
  ReasonsSectionContainer,
} from './styles';

import astronaut from '../../../../assets/img/Astronaut.png';

function ReasonsSection({ contactUsModalState }) {
  const [windowWidth] = useWindowSize();
  const isMobile = windowWidth <= 600;

  return (
    <ReasonsSectionContainer>
      {!isMobile && <Image bg={astronaut} />}
      <ContentContainer>
        <Header>
          Reasons <br />to Outsource:
        </Header>
        {isMobile && <Image bg={astronaut} />}
        <Checklist>
          <ChecklistItem>
            Cost reduction (operational and labor)
          </ChecklistItem>
          <ChecklistItem>
            Local recruitment issues
          </ChecklistItem>
          <ChecklistItem>
            Non-core business process delegation
          </ChecklistItem>
          <ChecklistItem>
            Avoid employment risks
          </ChecklistItem>
          <ChecklistItem>
            Geographical expansion and access to new markets
          </ChecklistItem>
        </Checklist>
        <Button onClick={contactUsModalState.toggle}>
          Get In Touch
        </Button>
      </ContentContainer>
    </ReasonsSectionContainer>
  );
}

ReasonsSection.propTypes = {
  contactUsModalState: PropTypes.object.isRequired,
};

export default ReasonsSection;
