import styled from 'styled-components';

export const SectionContainer = styled.section`
    color: white;
    min-height: 850px;
    max-width: 1440px;
    background-color: #000;
    margin: 0;
    overflow: hidden;
    scroll-snap-align: center;
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        min-height: 670px;
    };
    margin: auto;
`;

export const HeaderContainer = styled.div`
    margin-bottom: 120px;
    padding: 112px 180px 0 50px;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        padding: 64px 10px;
        margin-bottom: 0px;
    }
`;

export const AccordionContainer = styled.div`
    margin: 0 40px;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        margin: 0 10px;
    }
`;

export const Header = styled.div`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 48px;

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        font-size: 28px;
        line-height: 35px;
    }
`;

export const BackgroundText = styled.div`
    position: absolute;
    user-select: none;
    top: -20px;
    left: 0;
    opacity: 0.3;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: bold;
    font-size: 82px;
    line-height: 180%;
    color: #4F5562;

    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        display: none;
    }
`;

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    max-width: 960px;

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        font-size: 12px;
        line-height: 180%;
    }
`;
