import styled from 'styled-components';
import { theme } from '../../constants';

export const ModalWrapper = styled.div`
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: ${props => props.bg || theme.colors.charade};
    font-family: ${props => props.theme.fonts.sourceCodePro};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

export const ModalScrollContainer = styled.div`
    position: relative;
    padding-top: 40px;
    padding-bottom: 76px;
`;
