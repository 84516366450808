import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyledCheckbox, HiddenCheckbox, CheckboxContainer } from './styles';

function Checkbox({ onChange, label, id, ...inputProps }) {
  const [checked, setChecked] = useState(false);

  function toggleCheck() {
    onChange(!checked);
    setChecked(ch => !ch);
  }

  return (
    <CheckboxContainer id={id} onClick={toggleCheck}>
      <StyledCheckbox checked={checked} />
      <HiddenCheckbox checked={checked} {...inputProps} />
      <span style={{ width: '100%' }}>{label}</span>
    </CheckboxContainer>
  );
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string,
};

export default Checkbox;
