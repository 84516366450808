import React from 'react';
import PropTypes from 'prop-types';

import {
  ItemGroupContainer,
  ItemGroupTitle,
} from './styles';

function ItemGroup({ children, title, onClick, href }) {
  return (
    <>
      <ItemGroupTitle onClick={onClick && onClick} href={href && href}>{title}</ItemGroupTitle>
      {children && (
        <ItemGroupContainer>
          {children}
        </ItemGroupContainer>
      )}
    </>
  );
}

ItemGroup.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOf([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
};

export default ItemGroup;
