import styled from 'styled-components';
import arrowLight from '../../../assets/img/arrow_light.svg';

export const OpacityButtonStyled = styled.button`
    width: 170px;
    height: 50px;
    padding-left: 58px;
    padding-right: 10px;
    position: relative;
    border: none;
    background-color: rgba(40, 44, 52, 0.5);
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    &:hover {
        background-color: black;
        transform: scale(1.1);
    }
    &::before{
        content: '';
        display: block;
        width: 18px;
        height: 12px;
        position: absolute;
        left: 24px;
        top: 50%;
        margin-top: -6px;
        background: ${() => `url(${arrowLight})`};
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
`;
