import styled from 'styled-components';

import addFileSVG from '../../../assets/img/add_file.svg';
import { theme } from '../../../constants';

const { cadetBlue, fountainBlue, coral } = theme.colors;

export const FileInputHidden = styled.input.attrs({ type: 'file', required: true })`
    cursor: inherit;
    display: block;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    pointer-events: auto;
    left: 0;
    text-align: right;
    top: 0;
`;

export const FileInputStyled = styled.div`
    width: 100%;
    height: 50px;
    position: relative;
    padding-bottom: 16px;
    margin-top: 14px;
    padding-right: 52px;
    border: none;
    color: #FFFFFF;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    border-bottom: 2px solid ${cadetBlue}; 

    &:focus {
        outline: none;
        border-bottom-color: ${fountainBlue}; 
    };
    &:hover {
        border-bottom-color: ${fountainBlue}; 
    };

    & ~ label {
        color: ${cadetBlue};
        top: 31px;

        @media (max-width: ${p => p.theme.breakpoints.laptop}) {
            top: 16px;
        }
    }

    &:not([label=""]) ~ label,
    &:focus ~ label {
        top: 0px;
        font-size: 12px;
        font-style: regular;
        line-height: 13px;

        @media (max-width: ${p => p.theme.breakpoints.laptop}) {
            top: -10px;
        }
    }
`;

export const FileItemsContainer = styled.div`
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    place-items: none;
    margin-top: 14px;
    padding-bottom: 16px;
`;

export const FileItemStyled = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 10px;
`;

export const RemoveFileButton = styled.div`
    cursor: pointer;
    color: ${coral};
    pointer-events: all;
`;

export const AddFileIcon = styled.div`
    background: url(${addFileSVG}) center center no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 0px;
    z-index: 22;
`;
