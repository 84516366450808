/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PageScroll from '../../components/PageScroll';

import { FullPage } from '../../ui';

import {
  IntroSection, ExpertiseSection, TypingAnimationSection,
  MessageSection,
  ReviewSection,
} from './components';
import {
  expertise, expertiseSlider,
  partnersLogo,
  reviews,
} from '../../data';

import { Footer } from '../../components/Footer';
import useWindowSize from '../../hooks/useWindowSize';

function isScrolledIntoView(el) {
  if (!el) {
    return false;
  }

  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;

  var isVisible = (elemBottom <= window.innerHeight);
  return isVisible;
}

function HomePage({ contactUsModalState }) {
  const [typing, setTyping] = useState(false);
  const [windowWidth] = useWindowSize();
  const disablePageScroll = windowWidth <= 768;

  useEffect(() => {
    if (disablePageScroll) {
      const typingEl = document.querySelector('#typing');
      function onScroll() {
        if (isScrolledIntoView(typingEl)) {
          setTyping(true);
          document.querySelector('#home').removeEventListener('scroll', onScroll);
        };
      }

      document.querySelector('#home').addEventListener('scroll', onScroll);
    }
  }, []);

  function handlePageChange(page) {
    if (page === 1) {
      setTyping(true);
    }
  }

  return (
    <FullPage id="home" scrollable={disablePageScroll}>
      <PageScroll
        disabled={contactUsModalState.opened}
        renderAllPagesOnFirstRender
        pageOnChange={handlePageChange}
      >
        <IntroSection contactUsModalState={contactUsModalState}/>
        <TypingAnimationSection startTyping={typing} />
        <ExpertiseSection data={expertise} expertiseSlider={expertiseSlider} />
        <MessageSection partnersLogo={partnersLogo} contactUsModalState={contactUsModalState} />
        <ReviewSection reviews={reviews} />
        <Footer />
      </PageScroll>
    </FullPage>
  );
}

export default HomePage;
