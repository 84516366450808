import styled from 'styled-components';

export const Container = styled.div`
    color: white;
`;

export const AccordionItemContainer = styled.div`
    display: flex;
    transition: all 300ms ease-in;
    align-items: ${props => (props.opened ? 'flex-start' : 'center')};
    background-color: ${props => (props.opened ? '#282C34' : 'black')};
    transition: height 0.4s ease-out;
    width: 100%;
    border: 2px solid #282C34;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 40px;
    margin: 4px 0;
    justify-content: space-between;
    cursor: pointer;

    :hover {
        transition: all 300ms ease-in-out;
        background-color: #282C34;
    }

    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding: 20px 12px;
    }
`;

export const AccordionItemContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-right: 26%;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding-right: 18%;
    }
`;

export const Indicator = styled.span`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #ABB2BF;
    width: 44px;
    user-select: none;
`;

export const AccordionItemTitle = styled.div`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #E06C75;

    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        font-size: 16px;
        line-height: 20px;
    }
`;

export const AccordionItemDescription = styled.div`
    transition-duration: .4s;
    display: flex;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    margin-top: 24px;

    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        font-size: 12px;
        line-height: 180%;
    }
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`

`;
