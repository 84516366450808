import img from '../assets/img/review.jpg';

export default [
  {
    name: 'Nazar Kashuba',
    location: 'Founder & CEO of XchangePlus',
    description: 'Since day one Team Harbour has proved hardworking and dedicated to making our complex project a reality.',
  },
  {
    name: 'Angela Nickel',
    location: 'C.E.O. at COMO Global S.A.',
    description: 'Team Harbour took our below-average middleware and connectivity environment, and transformed it into a state of the art solution. It is and was an absolute pleasure to work with them. Anyone seeking a reference can call us anytime. You would be extremely unwise to overlook such a dedicated and professional team.',
  },
  {
    name: 'Praveen Pandey',
    location: 'Head of Delivery at Punchh, Inc',
    description: 'It is a pleasure working with Team Harbour, a highly professional and knowledgeable team, and their great attitude makes them a reliable partner for the implementation of our React Native apps.',
  },
  {
    name: 'Leo Zamanski',
    location: 'C.E.O. at Rina Systems, LLC',
    description: 'After five years of fruitful cooperation, we can confidently recommend Team Harbour as a highly qualified partner for web and mobile app development.',
  },
  {
    name: 'O. Kryukovsky',
    location: 'Chairman at VISA Global Bonus',
    description: 'High standards of performance and professionalism, great technical partner for the VISA Global Bonus project.',
  },
];
