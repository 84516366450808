import toBase64 from './base64';

export function FieldState(required) {
  this.value = '';
  this.valid = !required;
}

export const fieldsStateToString = fieldsState => Object.keys(fieldsState)
  .filter(key => typeof fieldsState[key].value === 'string' || typeof fieldsState[key].value === 'boolean')
  .reduce((a, nextKey) => `${a ? `${a}\n` : ''}${nextKey}: ${fieldsState[nextKey].value}`, '');

export const parseAttachment = fieldsState => toBase64(
  Object.values(fieldsState).find(fs => fs.attachment).value,
);

export const composeFieldsState = fieldsConfig => fieldsConfig.reduce((a, next) => {
  a[next.id] = new FieldState(next.required);
  return a;
}, {});

export const allValid = fieldsState => !Object.keys(fieldsState).some(key => fieldsState[key].valid === false);
