import styled from 'styled-components';
import { FullPageSection } from '../../../../ui';

export const ReasonsSectionContainer = styled(FullPageSection)`
    padding: 130px 40px 0 0;
    display: flex;
    justify-content: space-around;
    color: white;
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 180%;
    max-width: 1440px;
    margin: auto;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        flex-direction: column;
        height: auto;
        padding: 0px 10px;
    }
`;

export const Image = styled.div`
    width: 100%;
    background: url(${props => (props.bg)});
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        height: 300px;
        margin-bottom: 32px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 32px;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        flex-direction: column;
        height: auto;
        padding: 0;
    }
`;

export const Header = styled.div`
    font-style: normal;
    font-weight: 900;
    font-size: 78px;
    line-height: 104.5%;
    letter-spacing: -0.03em;
    color: #E5C07B;
    margin-bottom: 40px;

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        font-size: 36px;
        line-height: 100%;
    }
`;

export const Checklist = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 8px;
`;
