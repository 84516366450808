import styled from 'styled-components';

export const InputLabel = styled.label`
    color: white;
    font-weight: normal;
    font-size: 14px;
    position: absolute;
    pointer-events: none;
    left: 0px;
    transition: 300ms ease all;
`;
