import styled from 'styled-components';
import { Container, OpacityButton } from '../../../../ui';

export const DetailSliderItemText = styled.p`
    margin: 0;
    max-width: 590px;
    color: #fff;
    font-family: ${props => props.theme.fonts.poppins};
    font-size: 18px;
    line-height: 180%;
    font-weight: normal;
`;
export const DetailSliderItemTitle = styled.h4`
    margin: 0;
    max-width: 590px;
    color: #fff;
    font-weight: normal;
    font-family: ${props => props.theme.fonts.poppins};
    font-size: 24px;
    line-height: 180%;
`;
export const DetailSliderItemHeader = styled.h2`
    margin: 0;
    color: ${props => props.color || '#fff'};
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
    
`;

export const DetailSliderArrowNextStyled = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -28px;
    cursor: pointer;
    svg{
        width: 1em;
        height: 1em;
        font-size: 56px;
    }
`;

export const DetailSliderItemInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    ${OpacityButton}{
        margin-bottom: 30px;
    };
    ${DetailSliderItemHeader}{
        margin-bottom: 25px;
    };
    ${DetailSliderItemTitle}{
        margin-bottom: 32px;
    };
    ${DetailSliderItemText}{
        margin-bottom: 40px;
    };
    padding: 56px 40px;    
`;
export const DetailSliderItemStyled = styled.div`
    background: url(${p => p.bg}) center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding: 55px 0px;
    ${Container}{
        height: 100%;
    }
    ${DetailSliderItemInner}{
        height: 100%;
    }
`;
