/* eslint-disable indent */
import styled from 'styled-components';

import { theme } from '../../../../constants';
import { EmptyButton } from '../../../../ui';

export const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        display: block;
    }
`;

export const ActionsSeparator = styled.div`
    padding: 8px 16px;
    margin: 0 8px;
    font-family: ${props => props.theme.fonts.sourcePro};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${theme.colors.cadetBlue};
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        display: block;
    }
`;

export const LeaveCVButton = styled(EmptyButton)`
    width: 210px;
    height: 50px;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        width: 100%;
        height: 40px;
    }
    
`;

export const CheckOutJobsButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    width: 210px;
    height: 50px;
    border: none;
    & div {
        height: auto;
    }
    &:disabled {
        opacity: 0.3;
    }
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        width: 100%;
        height: 40px;
    }
    background-color: ${props => (props.type === 'light' && '#fff') ||
        props.theme.colors.coral
    }; 
    border-radius: 3px;
    color: ${props => (props.type === 'light' && '#000') ||
        '#fff'
    };
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border: 2px solid ${props => (props.type === 'light' && '#fff') ||
        props.theme.colors.coral
    };
    cursor: pointer;
    transition: all .4s;
    outline: none;
    &:hover{
        color: ${props => (props.type === 'light' && '#fff') ||
        props.theme.colors.coral
    };
        background-color: transparent;
    }
`;
