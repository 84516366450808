import bg from '../assets/img/bg_detail_slider.jpg';

export default [
  {
    name: 'XchangePlus',
    describe: 'Streamline your workflows and communication, improve customer service, and let Team Harbour guide your company through its digital transformation.',
    img: bg,
  },
  {
    name: 'Punchh',
    describe: 'Streamline your workflows and communication, improve customer service, and let Team Harbour guide your company through its digital transformation.',
    img: bg,
  },
  {
    name: 'Como',
    describe: 'Streamline your workflows and communication, improve customer service, and let Team Harbour guide your company through its digital transformation.',
    img: bg,
  },
];
