import styled from 'styled-components';

import { FormContainer } from '../Form/styles';
import { EmptyButton } from '../../../ui/buttons';

import { theme } from '../../../constants';

export const MessageContainer = styled(FormContainer)`
    height: 100%;
    align-items: center;
    display: flex;
`;

export const MessageText = styled.div`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    margin-top: 24px;
    color: white;
`;

export const HighlightText = styled.span`
    color: ${props => theme.colors[props.color]};
`;

export const MessageActionButton = styled(EmptyButton)`
    margin-top: 56px;
`;
