import styled from 'styled-components';
import { IntroSectionTitle, IntroSectionStyled } from '../../../../components/Intro/styles';
import { LinkButton } from '../../../../ui';

export const IntroWrapper = styled(IntroSectionStyled)`
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding: 120px 10px 64px 10px;
        min-height: auto;
        align-items: flex-start;
        height: auto;
        display: flex;
        min-height: 0px;
    }
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        min-height: 0px;
    };
    height: auto;
    min-height: 0px;
`;

export const Title = styled(IntroSectionTitle)`
    display: flex;
    flex-direction: column;
    max-width: 871px;
`;

export const ToggleButton = styled(LinkButton)`
    font-size: 14px;
    font-weight: normal;
`;
