import React from 'react';
import { LogoStyled, LogoText } from './styles';
import { theme } from '../../constants';

export const Logo = props => (
  <LogoStyled {...props}>
    <LogoText color={theme.colors.coral}>
            Team
    </LogoText>
    <LogoText color={theme.colors.cadetBlue}>
            .
    </LogoText>
    <LogoText color={theme.colors.fountainBlue}>
            Harbour
    </LogoText>
  </LogoStyled>
);
