import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpertiseListItem from './ExpertiseListItem';
import TypingScreen from './TypingScreen';
import ExpertiseSectionMobile from '../ExpertiseSectionMobile';
import {
  ExpertiseSectionStyled,
  HorizontalList,
  MainContainer,
} from './styles';
import useWindowSize from '../../../../hooks/useWindowSize';

export const ExpertiseSection = ({ data, expertiseSlider }) => {
  const [windowWidth] = useWindowSize();

  const listItems = data.map((item, index) => (
    <ExpertiseListItem expertiseSlider={expertiseSlider} key={index} {...item} startWith={index} />
  ));

  if (windowWidth <= 600) {
    return <ExpertiseSectionMobile />;
  }

  return (
    <MainContainer>
      <ExpertiseSectionStyled>
        <HorizontalList>
          {listItems}
        </HorizontalList>
      </ExpertiseSectionStyled>
    </MainContainer>
  );
};

ExpertiseSection.propTypes = {
  data: PropTypes.array.isRequired,
  expertiseSlider: PropTypes.array.isRequired,
};
