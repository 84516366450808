import xp from '../assets/img/logos/xp.svg';
import punch from '../assets/img/logos/punch.svg';
import rina from '../assets/img/logos/rina.svg';
import visa from '../assets/img/logos/visa.svg';
import como from '../assets/img/logos/como.svg';
// import goodwine from '../assets/img/logos/goodwine.svg';

export default [
  {
    logo: xp,
    name: 'exchange plus',
  },
  {
    logo: como,
    name: 'como',
  },
  {
    logo: punch,
    name: 'punchh',
  },
  {
    logo: rina,
    name: 'rina',
  },
  {
    logo: visa,
    name: 'visa',
  },
];
