import img1 from '../assets/img/horizontal_list_1.jpg';
import img2 from '../assets/img/horizontal_list_2.jpg';
import img3 from '../assets/img/horizontal_list_3.jpg';
import { theme } from '../constants';

export default [
  {
    img: img1,
    header: 'Enterprise Software',
    headerColor: theme.colors.harvestGold,
    text: 'Scale is no issue. We provide custom applications and development tools for organizations of any size to solve your business’s unique needs.',
  },
  {
    img: img2,
    header: 'Web Applications',
    headerColor: theme.colors.coral,
    text: 'We value efficiency, control, and usability in all of our industry focused web application products.',
  },
  {
    img: img3,
    header: 'Mobile Applications',
    headerColor: theme.colors.olivine,
    text: 'Team Harbour delivers peerless mobile experiences for both consumer-facing and corporate environments.',
  },
];
