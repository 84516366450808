import React from 'react';
import PropTypes from 'prop-types';

import {
  HeaderStyled, HeaderInner,
  HeaderNavSocialWrap, HeaderNav,
  HeaderNavItem, HeaderSocialNetworks,
  HeaderSocialNetworksItem, HeaderNavSubNav,
  HeaderNavSubNavItem, HeaderNavSubNavItemLink,
  HeaderNavItemLink,
} from './styles';
import { Logo } from '../Logo';
import { Container } from '../../ui';
import { IFacebook, ILinkedin } from '../../icons';

import { Routes } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import ContactUsModal from '../ContactUsModal';

const Header = ({ drawer, contactUsModalState }) => {
  const [windowWidth] = useWindowSize();
  const isDrawer = windowWidth <= 760;

  function renderContactUs() {
    return contactUsModalState.opened && (
      <ContactUsModal
        onClose={contactUsModalState.toggle}
      />
    );
  }

  if (isDrawer) {
    return (
      <HeaderStyled>
        {renderContactUs()}
        <Container>
          <HeaderInner>
            <Logo href={Routes.HOME} />
            <div onClick={drawer.toggle}>
              <svg width="40" height="10" viewBox="0 0 40 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="2" fill="white" />
                <rect y="8" width="40" height="2" fill="white" />
              </svg>
            </div>
          </HeaderInner>
        </Container>
      </HeaderStyled>
    );
  }

  return (
    <HeaderStyled>
      {renderContactUs()}
      <Container>
        <HeaderInner>
          <Logo href={Routes.HOME} />
          <HeaderNavSocialWrap>
            <HeaderNav>
              <HeaderNavItem>
                <HeaderNavItemLink href={Routes.ABOUT_US}>
                  About
                </HeaderNavItemLink>
              </HeaderNavItem>
              <HeaderNavItem>
                <HeaderNavItemLink href={Routes.SERVICES}>
                  Services
                </HeaderNavItemLink>
              </HeaderNavItem>
              <HeaderNavItem>
                <HeaderNavItemLink href={Routes.CAREERS}>
                Careers
                </HeaderNavItemLink>
              </HeaderNavItem>
              <HeaderNavItem>
                <HeaderNavItemLink onClick={contactUsModalState.toggle}>
                  Contact
                </HeaderNavItemLink>
              </HeaderNavItem>
            </HeaderNav>
            <HeaderSocialNetworks>
              <HeaderSocialNetworksItem href="https://www.facebook.com/yourteamharbour/" target="_blank">
                {IFacebook}
              </HeaderSocialNetworksItem>
              <HeaderSocialNetworksItem href="https://www.linkedin.com/company/team-harbour-inc" target="_blank">
                {ILinkedin}
              </HeaderSocialNetworksItem>
            </HeaderSocialNetworks>
          </HeaderNavSocialWrap>
        </HeaderInner>
      </Container>
    </HeaderStyled>
  );
};

Header.propTypes = {
  drawer: PropTypes.object.isRequired,
  contactUsModalState: PropTypes.object.isRequired,
};

export { Header };
