import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FileInputHidden,
  FileInputStyled,
  FileItemsContainer,
  AddFileIcon,
} from './styles';
import FileItem from './FileItem';

// eslint-disable-next-line react/prop-types
function FileInput({ id, onChange, value, ...props }) {
  const [files, setFiles] = useState([]);

  function handleChange({ target }) {
    setFiles([...target.files]);
    onChange([...target.files][0]);
  }

  function handleRemove() {
    setFiles([]);
  }

  return (
    <FileInputStyled label={files.length ? 'up' : ''}>
      <FileInputHidden
        id={id}
        files={files}
        onChange={handleChange}
        {...props}
      />
      <FileItemsContainer>
        {files.map(({ name }, index) => (
          <FileItem
            key={index}
            onRemove={handleRemove}
            {...{ name, index }}
          />
        ))}
      </FileItemsContainer>
      <AddFileIcon />
    </FileInputStyled>
  );
}

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,

};

export {
  FileInput,
};
