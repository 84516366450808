import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typing from 'react-typing-animation';

import {
  PreloadPrint,
  PreloadPrintText,
  PreloadPrintCursor,
  PreloadPrintCursorCustom,
  PreloadSectionStyled,
} from './styles';

const ANIMATION_SPEED = 20000;

function TypingScreen({ speed, delay, onFinish, children, startTyping }) {
  const [typingFinishedDelay, setTypingFinishedDelay] = useState(false);
  const [typingFinished, setTypingFinished] = useState(false);

  function handleTypingFinished() {
    setTimeout(() => {
      setTypingFinishedDelay(true);
    }, 0);
    setTimeout(() => {
      setTypingFinished(true);
    }, 2000);
  }

  return (
    <PreloadSectionStyled id="typing">
      <PreloadPrint>
        <PreloadPrintText>
          {startTyping && (
            <Typing speed={speed} cursor={<PreloadPrintCursor />} onFinishedTyping={handleTypingFinished}>
              {children}
            </Typing>
          )}
          {
            typingFinishedDelay && <PreloadPrintCursorCustom />
          }
        </PreloadPrintText>
      </PreloadPrint>
    </PreloadSectionStyled>
  );
}

TypingScreen.propTypes = {
  speed: PropTypes.number.isRequired,
  delay: PropTypes.number.isRequired,
  onFinish: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  startTyping: PropTypes.bool.isRequired,
};

export default TypingScreen;
