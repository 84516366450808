import styled from 'styled-components';
import {
  SectionTitle,
  SectionStyled,
  SectionHeader,
} from '../styles';

export const IntroSectionHeader = styled(SectionHeader)`
  font-size: 50px;
  @media (max-width: ${p => p.theme.breakpoints.laptop}) {
    font-size: 24px;
    line-height: 30px
  }
`;

export const IntroSectionTitle = styled(SectionTitle)`
  @media (max-width: ${p => p.theme.breakpoints.laptop}) {
    font-size: 18px;
    line-height: 180%;
  }
`;

export const IntroSectionStyled = styled(SectionStyled)`
  @media (max-width: ${p => p.theme.breakpoints.laptop}) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 98px;
    height: auto;
  }
`;
