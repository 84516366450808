import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../Modal';
import Form from '../../Forms/Form';
import {
  CancelButton,
  FormHeader,
  ContactUsButton,
  MessageContainer,
  MessageText,
} from './styles';

import formFields from './formFields';
import { sendMail } from '../../../services/mailService';
import { fieldsStateToString } from '../../../utils/formFields';

function LeaveContactModal({ onClose }) {
  const [result, setResult] = useState(null);

  async function handleSubmit(fieldsState) {
    const sent = await sendMail({
      subject: 'Contact Us',
      text: fieldsStateToString(fieldsState),
    });

    setResult({ error: !sent, success: sent });

    if (!sent) {
      setTimeout(onClose, 1000);
    }
  }

  function renderHeader() {
    return <FormHeader>To better understand what we can offer you, please answer a few questions:</FormHeader>;
  }

  function renderFooter(isValid) {
    return (<ContactUsButton type="submit" disabled={!isValid}>Write To Me</ContactUsButton>);
  }

  function renderForm() {
    return (
      <>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <Form
          fields={formFields}
          onSubmit={handleSubmit}
          renderHeader={renderHeader}
          renderFooter={renderFooter}
          fieldsContainerStyle={{ justifyContent: 'flex-end', height: '100%', display: 'flex', flexDirection: 'column' }}
        />
      </>
    );
  }

  function renderSuccess() {
    return (
      <MessageContainer>
        <MessageText>
          Your answers have been sent
        </MessageText>
      </MessageContainer>
    );
  }

  function renderError() {
    return (
      <MessageContainer>
        <MessageText>
          Error
        </MessageText>
      </MessageContainer>
    );
  }

  const submitted = !!result;
  const error = submitted && result.error;
  const success = submitted && result.success;

  return (
    <Modal wrapperProps={{ bg: '#E06C75' }}>
      {!submitted && renderForm()}
      {error && renderError()}
      {success && renderSuccess()}
    </Modal>
  );
}

LeaveContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default LeaveContactModal;
