import styled from 'styled-components';
import Slider from 'react-slick';
import { FullPageSection } from '../../../../ui';

export const MessageSectionText = styled.p`
    color: #fff;
    font-family: ${props => props.theme.fonts.poppins};
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        font-size: 12px;
    }
`;
export const MessageSectionHeader = styled.h2`
    color: #fff;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: bold;
    font-size: 36px;
    line-height: 1.2;
`;

export const LogoSliderItemImg = styled.img`
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    min-height: 100%;
`;
export const LogoSliderItem = styled.div`
    outline: none;
    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding-right: 34px;
    };
`;

export const LogoSlider = styled(Slider)`

    .slick-slide {
        max-width: 260px;
    }    
`;

export const MessageSectionStyled = styled(FullPageSection)`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 160px 0px;
    ${MessageSectionHeader}{
        margin-bottom: 50px;
    }
    ${MessageSectionText}{
        max-width: 982px;
        margin-bottom: 70px;
    }
    ${LogoSlider}{
      padding-bottom: 60px;  
    }
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        padding: 56px 0px;
    };
`;

export const MobileContainer = styled.div`
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        padding: 0px 10px;
    };
`;
