/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-inner-declarations */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';

import { Modal } from '../Modal';
import {
  Form,
  CancelButton,
  SubmitButton,
} from '../Forms';

import {
  MessageContainer,
  HighlightText,
  MessageText,
  MessageActionButton,
} from '../Forms/FormMessages';

import {
  HeaderContainer,
  AnimatedHeader,
  ScrollCaptionContainer,
  ScrollCaption,
  ScrollDecorative,
  HeaderMobile,
  HeaderMobileContainer,
} from './styles';

import { sendMail } from '../../services/mailService';
import formFields from './formFields';
import { fieldsStateToString } from '../../utils/formFields';
import useWindowSize from '../../hooks/useWindowSize';

const Animation = {
  from: {
    container: {
      config: { duration: 400 },
      height: '100vh',
    },
    header: {
      config: { duration: 400 },
      fontSize: '36px',
      lineHeight: '45px',
      paddingBottom: '22px',
    },
  },
  to: {
    container: {
      height: '15vh',
    },
    header: {
      fontSize: '24px',
      lineHeight: '30px',
      paddingBottom: '0px',
    },
  },
};

function ContactUsModal({ onClose }) {
  const [result, setResult] = useState(null);
  const [windowWidth] = useWindowSize();

  const scrollContainer = useRef();
  const [containerStyles, setContainerStyles] = useSpring(() => (Animation.from.container));
  const [headerStyles, setHeaderStyles] = useSpring(() => (Animation.from.header));
  const [scrollCaptionStyles, setScrollCaptionStyles] = useSpring(() => ({ config: { duration: 200 }, opacity: 1, height: '100px' }));

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.style.setProperty('overflow-y', 'hidden');
      function onScroll(e) {
        e.preventDefault();
        setContainerStyles(Animation.to.container);
        setHeaderStyles(Animation.to.header);
        setScrollCaptionStyles({ opacity: 0, height: '0px' });
        scrollContainer.current.removeEventListener('wheel', onScroll);
        scrollContainer.current.style.setProperty('overflow-y', 'auto');
      }
      scrollContainer.current.addEventListener('wheel', onScroll);
    }
  }, []);

  function resetResult() {
    setResult(null);
  }

  async function handleSubmit(fieldsState) {
    const sent = await sendMail({
      subject: 'Contact Us',
      text: fieldsStateToString(fieldsState),
    });

    setResult({ error: !sent, success: sent });
  }

  function renderHeader() {
    return (
      <HeaderContainer style={containerStyles}>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <AnimatedHeader style={headerStyles}>
          To better understand what we can offer you, please answer a few questions:
        </AnimatedHeader>
        <ScrollCaptionContainer style={scrollCaptionStyles}>
          <ScrollCaption>
            <div>SCROLL</div>
          </ScrollCaption>
          <ScrollDecorative />
          <ScrollDecorative />
        </ScrollCaptionContainer>
      </HeaderContainer>
    );
  }

  function renderHeaderMobile() {
    return (
      <HeaderMobileContainer>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <HeaderMobile>
          To better understand what we can offer you, please answer a few questions:
        </HeaderMobile>
      </HeaderMobileContainer>
    );
  }

  function renderFooter(isValid) {
    return (<SubmitButton type="submit" disabled={!isValid}>Get In Touch</SubmitButton>);
  }

  function renderForm() {
    return (
      <Form
        fields={formFields}
        onSubmit={handleSubmit}
        renderHeader={windowWidth >= 700 ? renderHeader : renderHeaderMobile}
        renderFooter={renderFooter}
      />
    );
  }

  function renderSuccess() {
    return (
      <MessageContainer>
        <MessageText>
          Your answers have been sent<br />
          <HighlightText color="olivine">successfully</HighlightText>
        </MessageText>
        <MessageActionButton onClick={onClose}>Ok</MessageActionButton>
      </MessageContainer>
    );
  }

  function renderError() {
    return (
      <MessageContainer>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <MessageText>
          <HighlightText color="coral">Error</HighlightText> sending
        </MessageText>
        <MessageActionButton onClick={resetResult}>Try Again</MessageActionButton>
      </MessageContainer>
    );
  }

  const submitted = !!result;
  const error = submitted && result.error;
  const success = submitted && result.success;

  return (
    <Modal scrollContainerRef={scrollContainer}>
      {!submitted && renderForm()}
      {error && renderError()}
      {success && renderSuccess()}
    </Modal>
  );
}

ContactUsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ContactUsModal;
