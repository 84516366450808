import React, { useState } from 'react';
import PropTypes from 'prop-types';

import pipe from '../../../utils/pipe';
import * as FormFieldUtils from '../../../utils/formFields';

import { FormContainer, FormFieldContainer, SubmitButton, FormStyled } from './styles';
import FormFieldFabric from '../FormFieldFabric';

function Form({ renderHeader, fields, renderFooter, onSubmit, fieldsContainerStyle }) {
  const [fieldsState, setFieldsState] = useState(FormFieldUtils.composeFieldsState(fields));

  const handleFormFieldChange = id => (getState, middleware) => event => {
    const state = pipe(getState, ...middleware)(event);
    setFieldsState(fs => ({ ...fs, [id]: state }));
  };

  function handleSubmit(event) {
    event.preventDefault();

    onSubmit(fieldsState);
  }

  const formIsValid = FormFieldUtils.allValid(fieldsState);

  return (
    <FormContainer>
      <FormStyled
        onSubmit={handleSubmit}
        style={{ ...fieldsContainerStyle }}
      >
        {renderHeader()}

        {fields.map(field => (
          <FormFieldContainer type={field.type}>
            <FormFieldFabric
              {...field}
              onChange={handleFormFieldChange(field.id)}
              value={fieldsState[field.id].value}
            />
          </FormFieldContainer>
        ))}

        {renderFooter ? renderFooter(formIsValid) : <SubmitButton disabled={!formIsValid}>Submit</SubmitButton>}

      </FormStyled>
    </FormContainer>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  renderHeader: PropTypes.func.isRequired,
  renderFooter: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      getState: PropTypes.func.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  fieldsContainerStyle: PropTypes.object,
};

export default Form;
