/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import CardsRow from '../CardsRow';
import Card from '../Card';
import {
  IntroSectionStyled,
  IntroSectionHeader,
  IntroSectionTitle,
} from './styles';

function IntroSection({ partners }) {
  function renderPartner(partner, index) {
    return (
      <Card
        style={{ marginRight: '24px' }}
        key={partner.name}
        image={partner.logo}
        name={partner.name}
      />
    );
  }

  return (
    <IntroSectionStyled>
      <div>

        <IntroSectionHeader>About us</IntroSectionHeader>
        <IntroSectionTitle>
          Since our inception, Team Harbour has delivered technology solutions that provide real, quantifiable results to businesses.
          <br /><br /><br />As part of Team Harbour you’ll be part of a global team of experienced individuals delivering exciting cutting-edge products and services to clients. This is the perfect environment for professional development opportunities and take on interesting and challenging tasks.
        </IntroSectionTitle>
      </div>
      <CardsRow
        indent
        title="Our clients:"
        containerStyle={{ justifyContent: 'space-between' }}
        cards={partners}
        renderCard={renderPartner}
      />
    </IntroSectionStyled>
  );
}

IntroSection.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string.isRequired,
    }),
  ),
};

export default IntroSection;
