import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Router } from './containers';
import { theme } from './constants';
import { Header } from './components';
import { AppStyled } from './styles';

import Drawer from './components/Header/Drawer';

import CookiePopup from './components/CookiePopup';
import { useModalState } from './hooks';

const App = () => {
  const drawer = useModalState(false);
  const contactUs = useModalState(false);

  return (
    <ThemeProvider theme={theme}>
      <AppStyled>
        <Drawer opened={drawer.opened} onCloseAction={drawer.close} contactUsModalState={contactUs} />
        <Header drawer={drawer} contactUsModalState={contactUs} />
        <Router contactUsModalState={contactUs} />
        <CookiePopup />
      </AppStyled>
    </ThemeProvider>
  );
};

export default App;
