/* exlint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import RCDrawer from 'rc-drawer';

import ItemGroup from './ItemGroup.js';
import {
  Menu,
  Item,
  CancelButton, HeaderSocialNetworksItem, HeaderSocialNetworks,
} from './styles';
import * as Routes from '../../constants/routes';
import { IFacebook, ILinkedin } from '../../icons';

function Drawer({ opened, onCloseAction, contactUsModalState }) {
  function handleContactButtonClick() {
    contactUsModalState.toggle();
  }

  const drawerOpened = opened && !contactUsModalState.opened;
  return (
    <RCDrawer open={drawerOpened} width="100vw">
      {drawerOpened && (
        <Menu className="drawer-menu">
          <CancelButton onClick={onCloseAction}>
            Close menu
          </CancelButton>
          <ItemGroup title="Company" href={Routes.HOME} />
          <ItemGroup title="About" href={Routes.ABOUT_US} />
          <ItemGroup title="Services" href={Routes.SERVICES} />
          <ItemGroup title="Careers" href={Routes.CAREERS} />
          <ItemGroup title="Contact" onClick={handleContactButtonClick} />
          <HeaderSocialNetworks style={{ position: 'absolute', bottom: '48px', left: '32px' }}>
            <HeaderSocialNetworksItem href="https://www.facebook.com/yourteamharbour/" target="_blank">
              {IFacebook}
            </HeaderSocialNetworksItem>
            <HeaderSocialNetworksItem href="https://www.linkedin.com/company/team-harbour-inc" target="_blank">
              {ILinkedin}
            </HeaderSocialNetworksItem>
          </HeaderSocialNetworks>
        </Menu>
      )}
    </RCDrawer>
  );
}

Drawer.propTypes = {
  opened: PropTypes.bool.isRequired,
  onCloseAction: PropTypes.func.isRequired,
  contactUsModalState: PropTypes.object.isRequired,
};

export default Drawer;
