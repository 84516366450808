import styled from 'styled-components';

export const LogoStyled = styled.a`
    text-decoration: none;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: 600;
    font-size: 16px;
    margin-left: -7px;
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        margin-left: 0px;
    }
`;

export const LogoText = styled.span`
    color: ${props => props.color};
`;
