import styled from 'styled-components';

export const FullPageSectionStyled = styled.section`
    height: 100vh;
    min-height: 850px;
    max-width: 1440px;
    margin: auto;
    background-color: #000;
    padding: 0px;
    overflow: hidden;
    scroll-snap-align: center;
    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        min-height: 670px;
    };

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        margin-top: 60px;
    };

    @media (max-width: ${p => p.theme.breakpoints.laptop}) {
        padding-right: 10px;
        padding-left: 10px;
    }
`;
