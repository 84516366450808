import styled from 'styled-components';

import { FullPageSection } from '../../ui';

export const ColorText = styled.span`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    color: ${props => props.color || '#fff'};
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.5px;
`;
export const ColorTextWrap = styled.p`
    opacity: 0.5;
    user-select: none;
`;
export const IntroSectionHeader = styled.h1`
    font-family: ${props => props.theme.fonts.sourceCodePro};
    color: #fff;
    font-weight: bold;
    font-size: 50px;
    line-height: 1.2;
    max-width: 810px;
    margin-left: 25px;
    margin-top: 5px;
    margin-bottom: 12px;

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        font-size: 28px;
        line-height: 120%;
    }
`;
export const IntroSectionTitle = styled.h3`
    font-family: ${props => props.theme.fonts.poppins};
    color: #fff;
    font-size: 18px;
    line-height: 1.8;
    font-weight: normal;
    max-width: 745px;
    margin-left: 25px;
    margin-top: 5px;
    margin-bottom: 5px;

    @media (max-width: ${p => p.theme.breakpoints.tablet}) {

        font-size: 12px;
        line-height: 180%;
    }
`;

export const HeaderWrap = styled.div`
    margin-bottom: 35px;
`;
export const TitleWrap = styled.div`
    margin-bottom: 45px;
`;

export const IntroSectionStyled = styled(FullPageSection)`
    display: flex;
    align-items: flex-end;
    padding-bottom: 160px;
    background-color: #000;

    @media (max-width: ${p => p.theme.breakpoints.desktop}) {
        padding-bottom: 90px;
    };
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        align-items: flex-start;
    };
`;

export const ActionsContainer = styled.div`
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        padding: 0px 10px;
    };
`;
