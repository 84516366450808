import styled from 'styled-components';

export const ButtonStyled = styled.button`
    width: 210px;
    height: 50px;
    border: none;
    &:disabled {
        opacity: 0.3;
    }
    @media (max-width: ${p => p.theme.breakpoints.tablet}) {
        width: 100%;
    }
    background-color: ${props => (props.type === 'light' && '#fff') ||
        props.theme.colors.coral
}; 
    border-radius: 3px;
    color: ${props => (props.type === 'light' && '#000') ||
        '#fff'
};
    font-family: ${props => props.theme.fonts.sourceCodePro};
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    border: 2px solid ${props => (props.type === 'light' && '#fff') ||
        props.theme.colors.coral
};
    cursor: pointer;
    transition: all .4s;
    outline: none;
    &:hover{
        color: ${props => (props.type === 'light' && '#fff') ||
            props.theme.colors.coral
};
        background-color: transparent;
    }
`;
