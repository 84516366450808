import styled from 'styled-components';
import Slick from 'react-slick';

const ReviewSection = styled.section`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
`;

const ItemContainer = styled.div`
    width: 100%;
`;

const Image = styled.div`
    position: relative;
    width: 300px;
    height: 400px;
    @media (max-width: ${p => p.theme.breakpoints.mobile.L}) {
        width: 256px;
        height: 316px;
    };

    @media (max-width: ${p => p.theme.breakpoints.mobile.M}) {
        width: 200px;
        height: 240px;
    };
    /* @media (max-width: ${p => p.theme.breakpoints.mobile.M}) {
        width: 300px;
        height: 400px;
    }; */
    background: url(${p => p.src}) center center no-repeat;
    background-size: contain;
`;

const CaptionContainer = styled.div`
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: -26px;
    margin: auto;
`;

const CaptionPanel = styled.div`
    width: 272px;
    height: 80px;
    background-color: #E06C75;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 16px 10px;
    font-size: 12px;
    line-height: 180%;
    font-family: ${props => props.theme.fonts.sourceCodePro};
    color: white;
`;
const NameCaption = styled.div`
    font-weight: bold;
    color: #000;
`;

const LocationCaption = styled.div`
    padding-top: 2px;
`;

const CardContent = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
`;

const SlideAction = styled.div`
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 2px;
    background-color: #191919;
    cursor: pointer;
    svg{
        width: 1em;
        height: 1em;
        font-size: 22px;
    }
`;

const SlideActions = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 24px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 80px 10px 24px 10px;

`;

const Slider = styled(Slick)`
    height: 100%;
    .slick-prev{
        display: none!important;
    };
    .slick-list, .slick-track{ 
        height: 100%
    }
    .slick-slide > div {
        height: 100%;
    }
`;

const DecorativeText = styled.div`
    position: absolute;
    @media (max-width: ${p => p.theme.breakpoints.mobile.M}) {
        top: 0px;
    };
    right: -46px;
    svg {
        height: 258px;
    }
`;

const GreyLine = styled.div`
    position: absolute;
    height: 100vh;
    width: 200px;
    background-color: #282C34;
`;

const Message = styled.div`
    font-family: ${props => props.theme.fonts.poppins};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    padding-bottom: 30px;
    line-height: 180%;
    color: #FFFFFF;
    width: 272px;
`;

export {
  ItemContainer,
  Image,
  Message,
  LocationCaption,
  NameCaption,
  CaptionContainer,
  CaptionPanel,
  CardContent,
  SlideAction,
  SlideActions,
  Container,
  Slider,
  DecorativeText,
  ReviewSection,
  GreyLine,
};
